import React, {useState,useContext,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {MyContext} from '../../login/MyContext';

import axios from 'axios';

const Supervisar = () => {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    
    const [country2, setName] = useState([]);
    const [country, setCountry] = useState([]);

    const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
        id:'',
        name:'',
        saldo:'',
      });

    const peticionGet =async() =>{
        
        console.log("nombre" + global.miID)

       
       
      await axios.get(`https://parqueoenvia.com/apiParqueoenvia/zonas_app/zonaclientes.php`)
      .then((res) => {
         setName(res.data);
       // console.log("nombrex" + res.data.celular);
      }).catch(error=>{
        console.log(error);
      })
    
    }


    useEffect(()=>{
    

      const interval = setInterval(() => {
        
         peticionGet();
         
       }, 1000);
   
     return () => setInterval(interval);
   
     },[])
   
     

   

  

    if(isAuth && theUser.tipo_usuario =="supervisor")
       {
  return (
    <div class="container" style={{marginTop:"-45px"}}>
          <div class="row g-2">   
         
          
          {console.log("usuario " + global.user_country2)}

         
          { true
          ?
            <div class="col-12">
              <div class="card team-member-card shadow">
                <div class="card-body">
                <Link to="/Sub_zona1" className="menu-link" >
                  <div class="team-member-img shadow-sm" style={{marginBottom:"0rem", maxWidth:"10rem"}} ><img src="img\parqueo\pa1.jpg" alt="" /></div>
                  </Link>
                
                </div>
               
               
                <div class="contact-info bg-info">
                  <p class="mb-0 text-truncate">ZONA SUR <img src="img\gif\cargando.gif" width="15px" alt="" /></p>
                </div>
              </div>
            </div>
          
          : ""
        }  
           
           { true
          ?
            
            <div class="col-12">
              <div class="card team-member-card shadow">
                <div class="card-body">
                <Link to="/Sub_zona2" className="menu-link" >
                  <div class="team-member-img shadow-sm" style={{marginBottom:"0rem", maxWidth:"10rem"}} ><img src="img\parqueo\pa2.jpg" alt="" /></div>
                  </Link>
                
                </div>

               
                <div class="contact-info bg-info">
                  <p class="mb-0 text-truncate">ZONA CENTRO <img src="img\gif\cargando.gif" width="15px" alt="" /></p>
                </div>
              </div>
            </div>
           
           : ""
        }  
           
           { true
          ?
            <div class="col-12">
              <div class="card team-member-card shadow">
                <div class="card-body">
                <Link to="/Sub_zona3" className="menu-link" >
                  <div class="team-member-img shadow-sm" style={{marginBottom:"0rem", maxWidth:"10rem"}} ><img src="img\parqueo\pa3.jpg" alt="" /></div>
                  </Link>
                
                </div>

               
                <div class="contact-info bg-info">
                  <p class="mb-0 text-truncate">ZONA NORTE <img src="img\gif\cargando.gif" width="15px" alt="" /></p>
                </div>
              </div>
            </div>
        : ""
        }  

            
      { true
          ?
            <div class="col-12">
              <div class="card team-member-card shadow">
                <div class="card-body">
                <Link to="/Sub_zona4" className="menu-link" >
                  <div class="team-member-img shadow-sm" style={{marginBottom:"0rem", maxWidth:"10rem"}} ><img src="img\parqueo\pa4.jpg" alt="" /></div>
                  </Link>
                
                </div>

               
                <div class="contact-info bg-info">
                  <p class="mb-0 text-truncate">ZONA OCCIDENTE <img src="img\gif\cargando.gif" width="15px" alt="" /></p>
                </div>
              </div>
            </div>
: ""
}  

          
            
          </div>

         
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
  )
}

else if(showLogin){
    return"";
}

}

export default Supervisar