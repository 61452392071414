import React, {useContext,useState,useEffect} from 'react';
import {MyContext} from '../login/MyContext';
import {Link} from 'react-router-dom';

import Im1 from '../assets/header/31.jpg';

import axios from 'axios';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import SoundAutoridad from '../views/autoridades/SoundAutoridad';

import SoundCliente from '../views/clientes/SoundCliente';

const fadeImages = [
 /* "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
  "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
  "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80"*/
];




function Header() {
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;



    const [ruta, setRuta1] = useState([]);
       
    const peticionGet_imagenes1 =async() =>{
           
    await axios.get(`https://parqueoenvia.com/apiParqueoenvia/publicidad_img2/index1.php?id='1'`)
    .then((res) => {
    
     setRuta1(res.data.ruta);
       
    }).catch(error=>{
      console.log(error);
    })
  
    
  }


  const [ruta2, setRuta2] = useState([]);
       
    const peticionGet_imagenes2 =async() =>{
           
    await axios.get(`https://parqueoenvia.com/apiParqueoenvia/publicidad_img2/index2.php?id='2'`)
    .then((res) => {
    
     setRuta2(res.data.ruta2);
       
    }).catch(error=>{
      console.log(error);
    })
  
  }

 
    const [ruta3, setRuta3] = useState([]);
       
    const peticionGet_imagenes3 =async() =>{
           
    await axios.get(`https://parqueoenvia.com/apiParqueoenvia/publicidad_img2/index3.php?id='3'`)
    .then((res) => {
    
     setRuta3(res.data.ruta3);
       
    }).catch(error=>{
      console.log(error);
    })
  
  }
  



    const [ocultar, setOcultar] = useState("");


    const [solicitud_recoger, setData]=useState([]);

    const peticionGet =async() =>{
      await axios.get(`https://parqueoenvia.com/apiParqueoenvia/autoridades/alarma.php`)
      .then(res=>{
       setData(res.data);
      }).catch(error=>{
        console.log(error);
      })
  
    }

   // {console.log("alarma " + solicitud_recoger)}

   const [id_operador, setData_u]=useState([]);

   const peticionGet_cliente =async() =>{
    await axios.get(`https://parqueoenvia.com/apiParqueoenvia/clientes/alarma.php?id_u=${global.user_id}`)
    .then(res=>{
     setData_u(res.data);
    }).catch(error=>{
      console.log(error);
    })

  }


    {console.log("alarma " + id_operador +  " "  + global.user_id)}

 useEffect(()=>{
    

      const interval = setInterval(() => {
        
        peticionGet();
        peticionGet_cliente();    
        peticionGet_imagenes1();
        peticionGet_imagenes2();
        peticionGet_imagenes3();
         
       }, 1000);
    
     return () => setInterval(interval);
    
     },[])

  
    return (
<>
<div class="header-area" id="headerArea">

{
           console.log(global.miID=theUser.id)
           

        }

        {
             console.log("ok" + global.miID)
        }
      <div class="container">
       
        <div class="header-content header-style-five position-relative d-flex align-items-center justify-content-between">
        
          <div class="logo-wrapper"><a href="#"><img src="img\core-img\logo.png" alt="" /></a></div>
       
          <div class="navbar--toggler" id="affanNavbarToggler" data-bs-toggle="offcanvas" data-bs-target="#affanOffcanvas" aria-controls="affanOffcanvas"><span class="d-block"></span><span class="d-block"></span><span class="d-block"></span></div>
        </div>
        
      </div>
    </div>

    

<div class="offcanvas offcanvas-start" id="affanOffcanvas" data-bs-scroll="true"  tabIndex="-1" aria-labelledby="affanOffcanvsLabel">
<button class="btn-close btn-close-white text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
<div class="offcanvas-body p-0">
 
  <div class="sidenav-wrapper">
  
    <div class="sidenav-profile bg-gradient">
      <div class="sidenav-style1"></div>
     
      <div class="user-profile"><img src="img\bg-img\user.png" alt="" /></div>
      
      <div class="user-info">
        <h6 class="user-name mb-0">{theUser.name}</h6><span>{theUser.tipo_usuario}</span>
      </div>
    </div>
   
    <ul class="sidenav-nav ps-0">
    {/*  <li><a href="page-home.html"><i class="bi bi-house-door"></i>Inicio</a></li>
      <li><a href="elements.html"><i class="bi bi-folder2-open"></i>Elements<span class="badge bg-danger rounded-pill ms-2">220+</span></a></li>
      <li><a href="pages.html"><i class="bi bi-collection"></i>Pages<span class="badge bg-success rounded-pill ms-2">100+</span></a></li>
      <li><a href="#"><i class="bi bi-cart-check"></i>Shop</a>
        <ul>
          <li><a href="page-shop-grid.html">Shop Grid</a></li>
          <li><a href="page-shop-list.html">Shop List</a></li>
          <li><a href="page-shop-details.html">Shop Details</a></li>
          <li><a href="page-cart.html">Cart</a></li>
          <li><a href="page-checkout.html">Checkout</a></li>
        </ul>
      </li>
      <li><a href="settings.html"><i class="bi bi-gear"></i>Settings</a></li>
      <li>
        <div className="night-mode-nav"><i className="bi bi-moon"></i>Night Mode
          <div class="form-check form-switch">
            <input class="form-check-input form-check-success" id="darkSwitch" type="checkbox" />
          </div>
        </div>
      </li>*/}
      <li><a href="javascript:;" onClick={logoutUser}><i class="bi bi-box-arrow-right"></i>Cerrar Sesión</a></li>
    </ul>
   
    {/*<div class="social-info-wrap"><a href="#"><i class="bi bi-facebook"></i></a><a href="#"><i class="bi bi-twitter"></i></a><a href="#"><i class="bi bi-linkedin"></i></a></div>*/}
   
    <div class="copyright-info">
      <p>2022 &copy; parqueoenvia.com</p>
    </div>
  </div>
</div>
</div>

<div class="page-content-wrapper">

<div class="tiny-slider-one-wrapper">
<div className="tiny-slider-one">
<Slide
  autoplay={true}
  slidesToShow={1}
>
  <div className="each-slide-effect">
    <div
      style={{
        //backgroundImage: 'url(https://parqueoenvia.com/apiParqueoenvia/publicidad_img/Imagenes/publicidad1.jpg)', 
        backgroundImage: `url(https://parqueoenvia.com/apiParqueoenvia/publicidad_img/Imagenes/${ruta})`,
        height:"220px",
        backgroundSize:"cover"
      }}
    >
      
    </div>
  </div>
  <div className="each-slide-effect">
    <div
      style={{
      // backgroundImage: 'url(https://parqueoenvia.com/apiParqueoenvia/publicidad_img/Imagenes/publicidad2.jpg)',
       backgroundImage: `url(https://parqueoenvia.com/apiParqueoenvia/publicidad_img/Imagenes/${ruta2})`,
        height:"220px",
        backgroundSize:"cover"
      }}
    >
     
    </div>
  </div>
  <div className="each-slide-effect">
    <div
    
      style={{
    //backgroundImage: 'url(https://parqueoenvia.com/apiParqueoenvia/publicidad_img/Imagenes/publicidad3.jpg)',
     backgroundImage: `url(https://parqueoenvia.com/apiParqueoenvia/publicidad_img/Imagenes/${ruta3})`,
        height:"220px",
        backgroundSize:"cover"
      }}
    >
      
    </div>
  </div>
</Slide>
{/*global.img='publicidad3.jpg'*/
      
    }
   
    </div>
    

    </div>

{/*  <div class="single-hero-slide bg-overlay" style={{backgroundImage: `url(${Im1})` }}>
              
*/}
{/*<div class="pt-3"></div>
<div class="container direction-rtl">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row g-3">
              <div class="col-4">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\dinero.png" alt="" /></div>
                  <p class="mb-0">Saldo</p>
                </div>
              </div>
              <div class="col-4">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\vahia.png" alt="" /></div>
                  <p class="mb-0">Vahías</p>
                </div>
              </div>
              <div class="col-4">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\puntos.png" alt="" /></div>
                  <p class="mb-0">P. Recargas</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    

      </div>


      
      <div class="footer-nav-area" id="footerNav">
      <div class="container px-0">
      {
       console.log(global.id=theUser.id)
    }

    {
       
      console.log(global.user_id=theUser.id, global.user_country=theUser.country,  global.user_country2=theUser.country2, global.user_subzona=theUser.id_subzona, global.user_id_unico=theUser.id_unico_subzona)
      
    }
        <div class="footer-nav position-relative">
          <ul class="h-100 d-flex align-items-center justify-content-between ps-0">
           
         
            <li class="active">  <Link to="/Principal" className="menu-link">
                <svg class="bi bi-house" width="20" height="20" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"></path>
                  <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"></path>
                </svg><span>Inicio</span></Link></li>
 
                { theUser.tipo_usuario =="cliente"
     ?
            <li> <Link to="/Historial" className="menu-link">
                <svg class="bi bi-folder2-open" width="20" height="20" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z"></path>
                </svg><span>Historial P.V</span>
                </Link></li>

      : ""
      } 


                { theUser.tipo_usuario =="cliente"
                ?
            <li>
              <Link to="/Mis_recargas" className="menu-link">
              <a href="pages.html">
                <svg class="bi bi-collection" width="20" height="20" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M14.5 13.5h-13A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5zm-13 1A1.5 1.5 0 0 1 0 13V6a1.5 1.5 0 0 1 1.5-1.5h13A1.5 1.5 0 0 1 16 6v7a1.5 1.5 0 0 1-1.5 1.5h-13zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"></path>
                </svg><span>Mis Recargas</span></a>
                </Link></li>

              : ""
              } 

         {/*      
                { theUser.tipo_usuario =="cliente"
     ?
        <li><a href="page-chat-users.html">
                <svg class="bi bi-chat-dots" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                  <path d="M2.165 15.803l.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"></path>
                </svg>
              
                <span>PQR</span></a></li>
                : ""
                } 

              */}

        {/*     { theUser.tipo_usuario =="cliente"
     ?
            <li><a href="settings.html">
                <svg class="bi bi-gear" width="20" height="20" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z"></path>
                  <path fillRule="evenodd" d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z"></path>
                </svg><span>Settings</span></a></li>  
                  : ""
                } */}


{ theUser.tipo_usuario =="operador"
     ?
            <li> <Link to="/Historial_operadores" className="menu-link">
                <svg class="bi bi-folder2-open" width="20" height="20" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z"></path>
                </svg><span>Historial</span>
                </Link></li>

      : ""
      } 


{ theUser.tipo_usuario =="operador"
     ?
            <li> <Link to="/Mis_arqueos" className="menu-link">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
  <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
</svg><span>Mis Arqueos</span>
                </Link></li>

      : ""
      }

     

{ theUser.tipo_usuario =="autoridad"  && solicitud_recoger != "0" 
?

<>

<li> <Link to="/Recoger" className="menu-link">
<img src="img\demo-img\alarma.gif" width="45" height="50" />
               
                </Link></li>

<div style={{display:"none"}}> 

<SoundAutoridad/>
</div>
</>
 : ""
}


{ theUser.tipo_usuario =="cliente"  && id_operador != "0" 
?

<>

<li> <Link to="/Recoger_vehiculo" className="menu-link">
<img src="img\demo-img\alarma.gif" width="45" height="50" />
               
                </Link></li>

<div style={{display:"none"}}> 

<SoundCliente/>
</div>
</>
 : ""
}


          </ul>
        </div>
      </div>
    </div>


    

     
      

      

      
</> 
        
    )
}

export default Header
