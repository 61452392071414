import React, {useState,useEffect,useContext} from 'react';
import axios from 'axios';

//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { AddBox, ArrowDownward } from "@material-ui/icons";

import {MyContext} from '../../login/MyContext';

//import SearchIcon from '@mui/icons-material/Search';
//import SearchIcon from '@material-ui/core/SearchIcon';



const No_registrados_revertir = () => {
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    const [data, setData]=useState([]);
    const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
        id:'',
        name:'',
        saldo:'',
      });


         ////////////////////////////////////////////////////////

    const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }

   

      const [modalInsertar, setModalInsertar]=useState(false);
      const [modalEditar, setModalEditar]=useState(false);

    const [saldo, setName] = useState([]);

    const baseUrlAd="https://parqueoenvia.com/apiParqueoenvia/operadores/se_fue.php"
   

    const peticionGet =async() =>{
        // await axios.get(baseUrlAd)
         //await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/historial.php?idPuntoVenta=${global.id}`)
        // await axios.get(`https://parqueoenvia.com/apiParqueoenvia/operadores/no_registrados_usuarios.php?id_operador=${global.id}`)
        await axios.get(`https://parqueoenvia.com/apiParqueoenvia/operadores/no_registrados_usuarios_revertir.php?id_unico_subzona=${global.user_id_unico}`)
         .then(response=>{
          setData(response.data);
         }).catch(error=>{
           console.log(error);
         })
     
       }


       const peticionPut = async() =>{
        var f = new FormData();
        f.append("se_fue", frameworkSeleccionado.se_fue);
        f.append("METHOD", "PUT_administrador");
        await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
        .then(response=>{
         var dataNueva = data;
         dataNueva.map(framework=>{
           if(framework.id===frameworkSeleccionado.id){
             framework.se_fue= frameworkSeleccionado.se_fue;
           }
         });
         setData(dataNueva);
         abrirCerrarModalEditar();
        }).catch(error=>{
          console.log(error);
        })
    
      }

     
      const handleChange=e=>{
        const {name, value}=e.target;
        setFrameworkSeleccionado((prevState)=>({
          ...prevState,
          [name]: value
        }))
       console.log(frameworkSeleccionado);
      }
    
      const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
      }
   

useEffect(()=>{
    

        const interval = setInterval(() => {
          
           peticionGet();
         //  peticionGet_vahias();
           
         }, 1000);
     
       return () => setInterval(interval);
     
       },[])


       const seleccionarFramework=(framework, caso)=>{
        setFrameworkSeleccionado(framework);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEditar()
    
    
      }


       const columnas =[    
        {
            title:"HORA_ENTRADA",
            field: "expDate"
          },

        {
          title:"RECOGER_VEHÍCULO",
          field: "expDate2"
        },

        {
         title:"PLACA_VEHÍCULO",
         field: "placa"
       },

        {
            title:"DOCUMENTO",
            field: "documento"
          },

          {
            title:"CELULAR",
            field: "celular"
          },
    
        
       
    
    
        
        
      ];
    
  
       if(isAuth)
       {
  return (
       <div class="container direction-rtl" style={{marginTop:"-45px"}}>
       
   {/* <div class="card timeline-card bg-success" >
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div class="timeline-text mb-2"><span class="badge mb-2 rounded-pill">HISTORIAL DE RECARGAS</span>
                <h5>${saldo} COP</h5>
              </div>
              <div class="timeline-icon mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
  <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
  <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
  <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
</svg>
              </div>
            </div>
                  </div>
        </div>
  */}


        <MaterialTable

     
columns={columnas}
data={data}
title= { <><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-bounding-box" viewBox="0 0 16 16">
<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z"/>
<path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
</svg></> }
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'api',
        tooltip: 'Ver Resumen',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
  
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>
<br/>
<br/>
<br/>

<Modal isOpen={modalEditar}>
 {/*<ModalHeader  style={{backgroundColor:"#F4F4F4",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">MI RECARGA</font></ModalHeader>*/}
 <ModalBody>
   <div className="form-group ">

   {/*<form className="fs-13px" method="post" action="https://parqueoenvia.com/email/reenvio_carga_rapida.php" target="_parent" name="reset" noValidate>*/}
   <div className="row">

 <div class="col-4 ">
     <label>Recarga:</label>
     <br/>
     <input type="number" placeholder="0" className="form-control " name="valor_t" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.valor_t} readOnly  />
     <br/>
     </div>

     

  

   
     <div class="col-5">
     <label>Documento:</label>
     <br/>
     <input type="text" className="form-control" name="documento" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.documento}  readOnly/>
     <br/>
     </div>

     <div class="col-3">
     <label>Tiempo:</label>
     <br/>
     <input type="text" className="form-control" name="valor" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.valor}  readOnly/>
     <br/>
     </div>


     <div class="form-group col-12 btn btn-danger" >
              <label class="form-label" for="defaultSelect" style={{color:"black"}}>Esta seguro de revertir la operación?</label>
              <select class="form-select form-control-clicked" id="se_fue" onChange={handleChange} name="se_fue" aria-label="Default select example">
                <option value="no" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>


     <div class="col-12 ">
  
     <label>Email:</label>
     <br/>
     <input type="text" className="form-control" name="email" autofocus onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.email} readOnly/>
     
     </div>

     <div class="col-12 ">
     <br/>
     <label>Fecha de ingreso recarga:</label>
     <br/>
     <input type="text" className="form-control" name="expDate" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.expDate} readOnly/>
     
     </div>

     <div class="col-12 ">
     <br/>
     <label>Fecha de terminación recarga:</label>
     <br/>
     <input type="text" className="form-control" name="expDate2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.expDate2} readOnly/>
     
     </div>

     <div class="col-12 ">
     <br/>
     <label>Nombres completos:</label>
     <br/>
     <input type="text" className="form-control" name="nombre" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.nombre} readOnly/>
     
     </div>


     <div class="col-6 ">
     <br/>
     <label>Celular:</label>
     <br/>
     <input type="text" className="form-control" name="celular" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.celular} readOnly/>
     
     </div>

     <div class="col-6 ">
     <br/>
     <label>Vehículo:</label>
     <br/>
     <input type="text" className="form-control" name="t_vehiculo" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.t_vehiculo} readOnly/>
     
     </div>

     <div class="col-6">
     <br/>
     <label>Placa:</label>
     <br/>
     <input type="text" className="form-control" name="placa" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.placa} readOnly/>
     
     </div>




     <div class="col-6 ">
        <br/>
     <label>Zona:</label>
     <br/>
     <input type="text" className="form-control" name="confirmar_zona_v" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.confirmar_zona_v} readOnly/>
     
     </div>


     <div class="col-12 ">
        <br/>
     <label>Sub-zona:</label>
     <br/>
     
     <textarea type="text" className="form-control" name="name_v" cols="3" rows="5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.name_v} readOnly/>
     </div>

  
     { frameworkSeleccionado.v1 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v1" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v1} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v2 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v2} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v3 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v3" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v3} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v4 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v4" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v4} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v5 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v5} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v6 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v6" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v6} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v7 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v7" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v7} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v8 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v8" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v8} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v9 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v9" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v9} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v10 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v10" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v10} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v11 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v11" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v11} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v12 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v12" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v12} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v13 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v13" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v13} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v14 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v14" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v14} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v15 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v15" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v15} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v16 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v16" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v16} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v17 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v17" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v17} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v18 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v18" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v18} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v19 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v19" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v19} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v20 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v20" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v20} readOnly/>
     
     </div>
 : ""
} 


<br/>
         <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1" style={{display:"none"}} >
          
  
            <div class="accordion-item">
              <div class="accordion-header" id="accordionTwo">
                <h6 class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordionStyleTwo" aria-expanded="false" aria-controls="accordionStyleTwo">Operador<i class="bi bi-chevron-down"></i></h6>
              </div>
              <div class="accordion-collapse collapse" id="accordionStyleTwo" aria-labelledby="accordionTwo" data-bs-parent="#accordionStyle1" >
                <div class="accordion-body">
                <input name="id_operador" className="form-control" type="text" placeholder="Id operador" value={frameworkSeleccionado && frameworkSeleccionado.id_operador} autoComplete="off" readOnly />
                </div>

                

                <div class="accordion-body">
                <input name="name_operador" className="form-control" type="text" placeholder="nombre operador" value={frameworkSeleccionado && frameworkSeleccionado.name_operador} autoComplete="off"  readOnly/>
                <input name="anulada" className="form-control" type="text" placeholder="anulada" value={frameworkSeleccionado && frameworkSeleccionado.anulada} autoComplete="off"  readOnly/>
                </div>

                
           

              </div>
            </div>
            
            
          </div>
     
     

   </div>
<br/>
   <button className="btn btn-success w-100" type="submit" onClick={()=>peticionPut()}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-bounding-box" viewBox="0 0 16 16">
  <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z"/>
  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
</svg> Procesar </button>
   {/*</form>*/}
   </div>
 </ModalBody>
 <ModalFooter>
  
<button className="btn btn-info w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>

 </ModalFooter>
</Modal>

        </div>
  )
}

else if(showLogin){
    return"";
}

}

export default No_registrados_revertir