import React, {useState,useEffect,useContext} from 'react';
import axios from 'axios';

//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { AddBox, ArrowDownward } from "@material-ui/icons";

import {MyContext} from '../../login/MyContext';

//import SearchIcon from '@mui/icons-material/Search';
//import SearchIcon from '@material-ui/core/SearchIcon';



const Arqueo = () => {
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    const [data, setData]=useState([]);
    const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
        id:'',
        name:'',
        saldo:'',
       // valor_t:''
      });

      const [lista, setLista] =  useState([]);
      const [imagen2, setImagen2] = useState(null);
      const [valor_t, setValort] = useState('');

      const [bandera, setBandera] = useState(true);

         ////////////////////////////////////////////////////////

    const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }

   

      const [modalInsertar, setModalInsertar]=useState(false);
      const [modalEditar, setModalEditar]=useState(false);

    const [saldo, setName] = useState([]);

 

    const peticionGet =async() =>{
         await axios.get(`https://parqueoenvia.com/apiParqueoenvia/operadores/arqueo_cargar.php?id_operador=${global.id}`)
         .then(response=>{
          setData(response.data);
         }).catch(error=>{
           console.log(error);
         })
     
       }


     /*  async function peticionGet(){
  
        const response = await axios.get(`https://parqueoenvia.com/apiParqueoenvia/operadores_img/index.php?id_operador=${global.id}`);
        setData(response.data)
        console.log(response.data)
    }

    */
    


       const [totalsuma, setSuma] = useState([]);

       const peticionGet_pagohoy =async() =>{
        await axios.get(`https://parqueoenvia.com/apiParqueoenvia/operadores/arqueo_suma.php?id_operador=${global.id}`)
        .then((res) => {  
            setSuma(res.data.valor_t);
        }).catch(error=>{
          console.log(error);
        })
    
      }

    
   
      async function addProducto(e) {
        // e.preventDefault();
         let fd = new FormData() 
         //fd.append("valor_t", valor_t)
         fd.append("imagen2", imagen2)
         const res = await axios.post('https://parqueoenvia.com/apiParqueoenvia/operadores_img/', fd);
         console.log(res.data)
        // getProductos();
        abrirCerrarModalInsertar();
        }


        function addUpdate(e) {
            e.preventDefault(); 
            bandera? addProducto():addProducto();
           // limpiarEstado();
        }

       /*const peticionPost = async() =>{
        var f = new FormData();
        f.append("valor_t", frameworkSeleccionado.valor_t);
        f.append("imagen2", imagen2)
        f.append("METHOD", "POST");
        await axios.post(`https://parqueoenvia.com/apiParqueoenvia/operadores/arqueo.php?id_operador=${global.id}`)
        .then(response=>{
         setData(data.concat(response.data));
         abrirCerrarModalInsertar();
        }).catch(error=>{
          console.log(error);
        })
    
      }*/

      const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
      }
   

     
      const handleChange=e=>{
        const {name, value}=e.target;
        setFrameworkSeleccionado((prevState)=>({
          ...prevState,
          [name]: value
        }))
       console.log(frameworkSeleccionado);
      }
    
      const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
      }
   

useEffect(()=>{
    

        const interval = setInterval(() => {
          
           peticionGet();
           peticionGet_pagohoy();
           
         }, 1000);
     
       return () => setInterval(interval);
     
       },[])


       const seleccionarFramework=(framework, caso)=>{
        setFrameworkSeleccionado(framework);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEditar()
    
    
      }

      //moneda
      const toMoney = (value) => {
        const money = Number(value);
    
        if (isNaN(money)) {
          return value;
        }
    
        return new Intl.NumberFormat("es-CO", {
          style: "currency",
          currency: "COP"
        }).format(money);
      };


       const columnas =[     
      
        {
          title:"FECHA",
          field: "fecha_hora"
        },

        {
            title:"PAGO",
          // type: "numeric",
          field: "valor_t2",
          render: (field) => toMoney(field.valor_t2)
    
          },
             
      ];

      
    
  
       if(isAuth && theUser.tipo_usuario =="operador")
       {
  return (
    
       <div class="container direction-rtl" style={{marginTop:"-45px"}}>
       
   {/* <div class="card timeline-card bg-success" >
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div class="timeline-text mb-2"><span class="badge mb-2 rounded-pill">HISTORIAL DE RECARGAS</span>
                <h5>${saldo} COP</h5>
              </div>
              <div class="timeline-icon mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
  <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
  <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
  <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
</svg>
              </div>
            </div>
                  </div>
        </div>
  */}


        <MaterialTable

     
columns={columnas}
data={data}
title= {<></> } 
backIconButtonText='Página anterior'
pagination
fixedHeader


actions={[

    {
        icon : 'ballot',
        tooltip: 'Ver Resumen',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
  
]}

renderSummaryRow={({ column, data }) =>
column.field === "valor_t2"
  ? {
      value: data.reduce((agg, row) => agg + Number(row.valor_t2), 0),
      style: { background: "red" },
    }
  : undefined
}

/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>
<br/>
<br/>
<br/>

<Modal isOpen={modalEditar}>
 {/*<ModalHeader  style={{backgroundColor:"#F4F4F4",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">MI RECARGA</font></ModalHeader>*/}
 <ModalBody>
   <div className="form-group ">

   <form className="fs-13px" method="post" action="https://parqueoenvia.com/email/reenvio_carga_rapida.php" target="_parent" name="reset" noValidate>
   <div className="row">

   <div class="col-12 ">
        <br/>
     <center><label>VALOR QUE DEBISTE PAGAR:</label></center>
     <br/>
     <input type="text" className="form-control" placeholder='0' name="valor_t" onChange={handleChange} value={frameworkSeleccionado.valor_t2}  
     style={{fontSize:"30px", textAlign:"center",height: "80px", maxHeight:"80px"}}
     readOnly/>
     
     </div>   

     <div class="col-12">
        <br/>
     <label>Tiempo:</label>
     <br/>
     <input type="text" className="form-control"  onChange={handleChange}  value={frameworkSeleccionado.fecha_hora}  readOnly/>
     <br/>
     </div>

   
     <div class="col-12">
     <label>Foto de pago:</label>
     <br/>
  <center><img  onChange={handleChange} src={"https://parqueoenvia.com/apiParqueoenvia/operadores_img/Imagenes/"+frameworkSeleccionado.foto} /></center>
     <br/>
     </div>


     

   

   
     

  

   

<br/>
         <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1" style={{display:"none"}} >
          
  
            <div class="accordion-item">
              <div class="accordion-header" id="accordionTwo">
                <h6 class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordionStyleTwo" aria-expanded="false" aria-controls="accordionStyleTwo">Operador<i class="bi bi-chevron-down"></i></h6>
              </div>
              <div class="accordion-collapse collapse" id="accordionStyleTwo" aria-labelledby="accordionTwo" data-bs-parent="#accordionStyle1" >
                <div class="accordion-body">
                <input name="id_operador" className="form-control" type="text" placeholder="Id operador" value={frameworkSeleccionado && frameworkSeleccionado.id_operador} autoComplete="off" readOnly />
                </div>

                

                <div class="accordion-body">
                <input name="name_operador" className="form-control" type="text" placeholder="nombre operador" value={frameworkSeleccionado && frameworkSeleccionado.name_operador} autoComplete="off"  readOnly/>
                </div>

                
           

              </div>
            </div>
            
            
          </div>
     
     

   </div>
  
   
   </form>
<br/>
   <button className="btn btn-info w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Aceptar</button>
   </div>
 </ModalBody>
 <ModalFooter>
  


 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
 <ModalBody>
   <div className="form-group">
   <div className="row">

        <div class="col-12 ">
        <br/>
     <center><label>VALOR A PAGAR HOY:</label></center>
     <br/>
     <input type="text" className="form-control" placeholder='0' name="valor_t" onChange={handleChange} value={totalsuma}  
     style={{fontSize:"30px", textAlign:"center",height: "80px", maxHeight:"80px"}}
     readOnly/>
     
     </div>   



            <div class="col-12">
   
    <br/>
    
     <div class="form-group">
        <br/>
                <label class="form-label" for="customFile">Sube copia del pago</label>
                <input class="form-control border-0 form-control-clicked" id="customFile" type="file"
                onChange={(e) => setImagen2(e.target.files[0])} />
              </div>
     </div>

   <label>Operador: {theUser.name}</label>

     
  
    

     </div>
   </div>
 </ModalBody>
 <ModalFooter>
 <button  className="btn btn-primary btn-lg" 
               onClick={(e) => addUpdate(e)} >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wallet2" viewBox="0 0 16 16"
   onClick={()=>addProducto()}>
  <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
</svg> 
                 {bandera?" PROCESAR":"Edit"}
                </button> {" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 
   

 </ModalFooter>
</Modal>




        </div>
  )
}

else if(showLogin){
    return"";
}

}

export default Arqueo