import React, {useState,useEffect,useContext} from 'react';
import axios from 'axios';


import MaterialTable from '@material-table/core';
//import { esES } from '@material/locale';
//import MaterialTable from "material-table";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { AddBox, ArrowDownward } from "@material-ui/icons";



import {MyContext} from '../../login/MyContext';

//import SearchIcon from '@mui/icons-material/Search';
//import SearchIcon from '@material-ui/core/SearchIcon';



const Ganancias_totales = () => {
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    const [data, setData]=useState([]);
    const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
        id:'',
        name:'',
        saldo:'',
      });


         ////////////////////////////////////////////////////////

    const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }

   

      const [modalInsertar, setModalInsertar]=useState(false);
      const [modalEditar, setModalEditar]=useState(false);

    const [saldo, setName] = useState([]);


   

    const peticionGet =async() =>{
        // await axios.get(baseUrlAd)
         //await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/historial.php?idPuntoVenta=${global.id}`)
         await axios.get(`https://parqueoenvia.com/apiParqueoenvia/visores/ganancia_total.php`)
         .then(response=>{
          setData(response.data);
         }).catch(error=>{
           console.log(error);
         })
     
       }

   
     
      const handleChange=e=>{
        const {name, value}=e.target;
        setFrameworkSeleccionado((prevState)=>({
          ...prevState,
          [name]: value
        }))
       console.log(frameworkSeleccionado);
      }
    
      const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
      }
   

useEffect(()=>{
    

        const interval = setInterval(() => {
          
           peticionGet();
         //  peticionGet_vahias();
           
         }, 1000);
     
       return () => setInterval(interval);
     
       },[])


       const seleccionarFramework=(framework, caso)=>{
        setFrameworkSeleccionado(framework);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEditar()
    
    
      }


      //moneda
      const toMoney = (value) => {
        const money = Number(value);
    
        if (isNaN(money)) {
          return value;
        }
    
        return new Intl.NumberFormat("es-CO", {
          style: "currency",
          currency: "COP"
        }).format(money);
      };


       const columnas =[      
        {
          title:"FECHA",
          field: "expDate"
        },

        {
            title:"VALOR",
            field: "valor_t",
            render: (field) => toMoney(field.valor_t)
          },

        

        {
            title:"DOCUMENTO",
            field: "documento"
          },

          {
            title:"CELULAR",
            field: "celular"
          },

         
    
        
       
    
    
        
        
      ];
    
  
       if(isAuth && theUser.tipo_usuario =="visor")
       {
  return (
       <div class="container direction-rtl" style={{marginTop:"-45px"}}>
       
   {/* <div class="card timeline-card bg-success" >
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div class="timeline-text mb-2"><span class="badge mb-2 rounded-pill">HISTORIAL DE RECARGAS</span>
                <h5>${saldo} COP</h5>
              </div>
              <div class="timeline-icon mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
  <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
  <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
  <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
</svg>
              </div>
            </div>
                  </div>
        </div>
  */}

{data.length === 0 ? (
    <div class="toast custom-toast-1 toast-info mb-2 fade show" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="5000" data-bs-autohide="false">
    <div class="toast-body">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16">
  <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
  <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
</svg>
    <div class="toast-text ms-3 me-2 ">
        <p class="mb-0 text-white">Cargando...</p>
      </div>
    </div> 
    
  </div>
          
        ) : (
        <MaterialTable

     
columns={columnas}
data={data}
title= { <><i class="fas fa-hands-helping fa-2x"></i>Tabla&nbsp;</> }
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'accessibility',
        tooltip: 'Ver Resumen',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
  
]}

renderSummaryRow={({ column, data }) =>
              column.field === "valor_t"
                ? {
                    value: toMoney(
                      data.reduce((agg, row) => agg + Number(row.valor_t), 0)
                    ),
                    style: { background: "#BFF7D4" }
                  }
                : undefined
            }
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}


/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>

)}
<br/>
<br/>
<br/>

<Modal isOpen={modalEditar}>
 {/*<ModalHeader  style={{backgroundColor:"#F4F4F4",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">MI RECARGA</font></ModalHeader>*/}
 <ModalBody>
   <div className="form-group ">

   {/*<form className="fs-13px" method="post" action="https://parqueoenvia.com/email/reenvio_carga_rapida.php" target="_parent" name="reset" noValidate> */}
   <div className="row">

 <div class="col-4 ">
     <label>Recarga:</label>
     <br/>
     <input type="number" placeholder="0" className="form-control " name="valor_t" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.valor_t} readOnly  />
     <br/>
     </div>

  

   
     <div class="col-5">
     <label>Documento:</label>
     <br/>
     <input type="text" className="form-control" name="documento" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.documento}  readOnly/>
     <br/>
     </div>

     <div class="col-3">
     <label>Tiempo:</label>
     <br/>
     <input type="text" className="form-control" name="valor" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.valor}  readOnly/>
     <br/>
     </div>

     <div class="col-12 ">
  
     <label>Email:</label>
     <br/>
     <input type="text" className="form-control" name="email" autofocus onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.email}/>
     
     </div>

     <div class="col-12 ">
     <br/>
     <label>Fecha de ingreso recarga:</label>
     <br/>
     <input type="text" className="form-control" name="expDate" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.expDate} readOnly/>
     
     </div>

     <div class="col-12 ">
     <br/>
     <label>Fecha de terminación recarga:</label>
     <br/>
     <input type="text" className="form-control" name="expDate2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.expDate2} readOnly/>
     
     </div>

     <div class="col-12 ">
     <br/>
     <label>Nombres completos:</label>
     <br/>
     <input type="text" className="form-control" name="nombre" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.nombre} readOnly/>
     
     </div>


     <div class="col-6 ">
     <br/>
     <label>Celular:</label>
     <br/>
     <input type="text" className="form-control" name="celular" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.celular} readOnly/>
     
     </div>

     <div class="col-6 ">
     <br/>
     <label>Vehículo:</label>
     <br/>
     <input type="text" className="form-control" name="t_vehiculo" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.t_vehiculo} readOnly/>
     
     </div>

     <div class="col-6">
     <br/>
     <label>Placa:</label>
     <br/>
     <input type="text" className="form-control" name="placa" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.placa} readOnly/>
     
     </div>




     <div class="col-6 ">
        <br/>
     <label>Zona:</label>
     <br/>
     <input type="text" className="form-control" name="confirmar_zona_v" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.confirmar_zona_v} readOnly/>
     
     </div>


     <div class="col-12 ">
        <br/>
     <label>Sub-zona:</label>
     <br/>
     
     <textarea type="text" className="form-control" name="name_v" cols="3" rows="5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.name_v} readOnly/>
     </div>

  
     { frameworkSeleccionado.v1 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v1" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v1} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v2 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v2} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v3 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v3" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v3} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v4 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v4" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v4} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v5 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v5} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v6 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v6" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v6} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v7 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v7" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v7} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v8 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v8" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v8} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v9 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v9" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v9} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v10 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v10" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v10} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v11 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v11" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v11} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v12 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v12" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v12} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v13 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v13" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v13} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v14 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v14" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v14} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v15 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v15" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v15} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v16 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v16" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v16} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v17 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v17" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v17} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v18 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v18" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v18} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v19 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v19" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v19} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v20 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v20" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v20} readOnly/>
     
     </div>
 : ""
} 


<br/>
         <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1" style={{display:"none"}} >
          
  
            <div class="accordion-item">
              <div class="accordion-header" id="accordionTwo">
                <h6 class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordionStyleTwo" aria-expanded="false" aria-controls="accordionStyleTwo">Operador<i class="bi bi-chevron-down"></i></h6>
              </div>
              <div class="accordion-collapse collapse" id="accordionStyleTwo" aria-labelledby="accordionTwo" data-bs-parent="#accordionStyle1" >
                <div class="accordion-body">
                <input name="id_operador" className="form-control" type="text" placeholder="Id operador" value={frameworkSeleccionado && frameworkSeleccionado.id_operador} autoComplete="off" readOnly />
                </div>

                

                <div class="accordion-body">
                <input name="name_operador" className="form-control" type="text" placeholder="nombre operador" value={frameworkSeleccionado && frameworkSeleccionado.name_operador} autoComplete="off"  readOnly/>
                <input name="anulada" className="form-control" type="text" placeholder="anulada" value={frameworkSeleccionado && frameworkSeleccionado.anulada} autoComplete="off"  readOnly/>
                </div>

                
           

              </div>
            </div>
            
            
          </div>
     
     

   </div>
<br/>
 {/*  <button className="btn btn-success w-100" type="submit" onClick={loadData} disabled={loading}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-check-fill" viewBox="0 0 16 16">
  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z"/>
  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z"/>
</svg> Reenviar copia </button> */}
  {/* </form> */ }
   </div>
 </ModalBody>
 <ModalFooter>
  
<button className="btn btn-info w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Aceptar</button>

 </ModalFooter>
</Modal>

        </div>
  )
}

else if(showLogin){
    return"";
}

}

export default Ganancias_totales