import React, {useState,useEffect,useContext} from 'react';
import axios from 'axios';

//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { AddBox, ArrowDownward } from "@material-ui/icons";

import {MyContext} from '../../login/MyContext';

//import SearchIcon from '@mui/icons-material/Search';
//import SearchIcon from '@material-ui/core/SearchIcon';



const Anular_recarga = () => {
    const baseUrlAd="https://parqueoenvia.com/apiParqueoenvia/operadores_img/index.php"
    const baseUrlAdEdit="https://parqueoenvia.com/apiParqueoenvia/operadores/anular.php"
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    const [data, setData]=useState([]);
    const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
        id:'',
        valor_t2:'',
        foto:''
      });

      const [foto, setImagen2] = useState(null);
      const [valor_t, setValort] = useState('');

      const [bandera, setBandera] = useState(true);

         ////////////////////////////////////////////////////////

    const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }

   

      const [modalInsertar, setModalInsertar]=useState(false);
      const [modalEditar, setModalEditar]=useState(false);

    const [saldo, setName] = useState([]);

 

    


    const peticionGet =async() =>{
         await axios.get(`https://parqueoenvia.com/apiParqueoenvia/operadores/anular.php?id_operador=${global.id}`)
         .then(response=>{
          setData(response.data);
         }).catch(error=>{
           console.log(error);
         })
     
       }




       const [totalsuma, setSuma] = useState([]);

       const peticionGet_pagohoy =async() =>{
        await axios.get(`https://parqueoenvia.com/apiParqueoenvia/operadores/arqueo_suma.php?id_operador=${global.id}`)
        .then((res) => {  
            setSuma(res.data.valor_t);
        }).catch(error=>{
          console.log(error);
        })
    
      }

      const peticionPut = async() =>{
        var f = new FormData();
        
        f.append("METHOD", "PUT_administrador");
        await axios.post(baseUrlAdEdit,f, {params: {id: frameworkSeleccionado.id}})
        .then(response=>{
         var dataNueva = data;
         dataNueva.map(framework=>{
           if(framework.id===frameworkSeleccionado.id){
           }
         });
         setData(dataNueva);
         abrirCerrarModalEditar();
        }).catch(error=>{
          console.log(error);
        })
    
      }

    
   
      /*async function addProducto(e) {
        // e.preventDefault();
         let fd = new FormData() 
         //fd.append("valor_t", valor_t)
         fd.append("imagen2", imagen2)
         const res = await axios.post('https://parqueoenvia.com/apiParqueoenvia/operadores_img/', fd);
         console.log(res.data)
        // getProductos();
        abrirCerrarModalInsertar();
        }

        */


      /*  function addUpdate(e) {
            e.preventDefault(); 
            bandera? peticionPost():peticionPost();
           // limpiarEstado();
        }*/

     
      const peticionPost = async() =>{
        var f = new FormData();
        f.append("valor_t2", frameworkSeleccionado.valor_t2);
        f.append("foto", frameworkSeleccionado.foto);
        f.append("METHOD", "POST");
        await axios.post(baseUrlAd,f)
        .then(response=>{
         setData(data.concat(response.data));
         abrirCerrarModalInsertar();
        }).catch(error=>{
          console.log(error);
        })
    
      }

      const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
      }
   
/*

       const [v1, setV1] = useState([]);
       const [v2, setV2] = useState([]);
       const [v3, setV3] = useState([]);
       const [v4, setV4] = useState([]);
       const [v5, setV5] = useState([]);
       const [v6, setV6] = useState([]);
       const [v7, setV7] = useState([]);
       const [v8, setV8] = useState([]);
       const [v9, setV9] = useState([]);
       const [v10, setV10] = useState([]);
       const [v11, setV11] = useState([]);
       const [v12, setV12] = useState([]);
       const [v13, setV13] = useState([]);
       const [v14, setV14] = useState([]);
       const [v15, setV15] = useState([]);
       const [v16, setV16] = useState([]);
       const [v17, setV17] = useState([]);
       const [v18, setV18] = useState([]);
       const [v19, setV19] = useState([]);
       const [v20, setV20] = useState([]);
          
     
       const peticionGet_vahias =async() =>{
             
         console.log("nombrevahia" + global.user_subzona)
     
        
       await axios.get(`https://parqueoenvia.com/apiParqueoenvia/zonas_app/vahias.php?country=${global.user_subzona}`)
       .then((res) => {
        
          setV1(res.data.v1);
          setV2(res.data.v2);
          setV3(res.data.v3);
          setV4(res.data.v4);
          setV5(res.data.v5);
          setV6(res.data.v6);
          setV7(res.data.v7);
          setV8(res.data.v8);
          setV9(res.data.v9);
          setV10(res.data.v10);
          setV11(res.data.v11);
          setV12(res.data.v12);
          setV13(res.data.v13);
          setV14(res.data.v14);
          setV15(res.data.v15);
          setV16(res.data.v16);
          setV17(res.data.v17);
          setV18(res.data.v18);
          setV19(res.data.v19);
          setV20(res.data.v20);
      
       }).catch(error=>{
         console.log(error);
       })
     
     }
*/



     
      const handleChange=e=>{
        const {name, value}=e.target;
        setFrameworkSeleccionado((prevState)=>({
          ...prevState,
          [name]: value
        }))
       console.log(frameworkSeleccionado);
      }
    
      const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
      }
   

useEffect(()=>{
    

        const interval = setInterval(() => {
          
           peticionGet();
           peticionGet_pagohoy();
           
         }, 1000);
     
       return () => setInterval(interval);
     
       },[])


       const seleccionarFramework=(framework, caso)=>{
        setFrameworkSeleccionado(framework);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEditar()
    
    
      }


       const columnas =[      
        {
          title:"FECHA",
          field: "expDate"
        },
        {
            title:"Anulada",
            field: "anulada"
          },
          {
            title:"VALOR",
            field: "valor_t"
          },
          {
            title:"VEHÍCULO",
            field: "t_vehiculo"
          },

          {
            title:"PLACA",
            field: "placa"
          },
    
        
       
    
    
        
        
      ];
    
  
       if(isAuth)
       {
  return (
    
       <div class="container direction-rtl" style={{marginTop:"-45px"}}>
     
   {/* <div class="card timeline-card bg-success" >
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div class="timeline-text mb-2"><span class="badge mb-2 rounded-pill">HISTORIAL DE RECARGAS</span>
                <h5>${saldo} COP</h5>
              </div>
              <div class="timeline-icon mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
  <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
  <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
  <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
</svg>
              </div>
            </div>
                  </div>
        </div>
  */}


        <MaterialTable

     
columns={columnas}
data={data}
title= {<><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
</svg></> } 
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'block',
        tooltip: 'Anular recarga',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
  
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>
<br/>
<br/>
<br/>

<Modal isOpen={modalEditar}>
 {/*<ModalHeader  style={{backgroundColor:"#F4F4F4",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">MI RECARGA</font></ModalHeader>*/}
 <ModalBody>
   <div className="form-group ">

   <form className="fs-13px" method="post" action="https://parqueoenvia.com/email/reenvio_carga_rapida.php" target="_parent" name="reset" noValidate>
   <div className="row">

 <div class="col-4 ">
     <label>Recarga:</label>
     <br/>
     <input type="number" placeholder="0" className="form-control " name="valor_t" onChange={handleChange} value={0} readOnly  />
     <br/>
     </div>

  

   
     <div class="col-5">
     <label>Documento:</label>
     <br/>
     <input type="text" className="form-control" name="documento" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.documento}  readOnly/>
     <br/>
     </div>

     <div class="col-3">
     <label>Tiempo:</label>
     <br/>
     <input type="text" className="form-control" name="valor" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.valor}  readOnly/>
     <br/>
     </div>

     <div class="col-12 ">
  
     <label>Email:</label>
     <br/>
     <input type="text" className="form-control" name="email" autofocus onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.email} readOnly/>
     
     </div>

     <div class="col-12 ">
     <br/>
     <label>Fecha de ingreso recarga:</label>
     <br/>
     <input type="text" className="form-control" name="expDate" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.expDate} readOnly/>
     
     </div>

     <div class="col-12 ">
     <br/>
     <label>Fecha de terminación recarga:</label>
     <br/>
     <input type="text" className="form-control" name="expDate2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.expDate2} readOnly/>
     
     </div>

     <div class="col-12 ">
     <br/>
     <label>Nombres completos:</label>
     <br/>
     <input type="text" className="form-control" name="nombre" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.nombre} readOnly/>
     
     </div>


     <div class="col-6 ">
     <br/>
     <label>Celular:</label>
     <br/>
     <input type="text" className="form-control" name="celular" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.celular} readOnly/>
     
     </div>

     <div class="col-6 ">
     <br/>
     <label>Vehículo:</label>
     <br/>
     <input type="text" className="form-control" name="t_vehiculo" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.t_vehiculo} readOnly/>
     
     </div>

     <div class="col-6">
     <br/>
     <label>Placa:</label>
     <br/>
     <input type="text" className="form-control" name="placa" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.placa} readOnly/>
     
     </div>




     <div class="col-6 ">
        <br/>
     <label>Zona:</label>
     <br/>
     <input type="text" className="form-control" name="confirmar_zona_v" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.confirmar_zona_v} readOnly/>
     
     </div>


     <div class="col-12 ">
        <br/>
     <label>Sub-zona:</label>
     <br/>
     
     <textarea type="text" className="form-control" name="name_v" cols="3" rows="5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.name_v} readOnly/>
     </div>

  
     { frameworkSeleccionado.v1 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v1" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v1} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v2 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v2} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v3 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v3" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v3} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v4 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v4" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v4} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v5 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v5} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v6 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v6" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v6} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v7 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v7" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v7} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v8 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v8" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v8} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v9 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v9" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v9} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v10 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v10" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v10} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v11 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v11" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v11} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v12 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v12" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v12} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v13 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v13" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v13} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v14 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v14" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v14} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v15 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v15" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v15} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v16 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v16" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v16} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v17 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v17" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v17} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v18 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v18" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v18} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v19 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v19" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v19} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v20 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v20" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v20} readOnly/>
     
     </div>
 : ""
} 
   

<br/>
         <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1" style={{display:"none"}} >
          
  
            <div class="accordion-item">
              <div class="accordion-header" id="accordionTwo">
                <h6 class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordionStyleTwo" aria-expanded="false" aria-controls="accordionStyleTwo">Operador<i class="bi bi-chevron-down"></i></h6>
              </div>
              <div class="accordion-collapse collapse" id="accordionStyleTwo" aria-labelledby="accordionTwo" data-bs-parent="#accordionStyle1" >
                <div class="accordion-body">
                <input name="id_operador" className="form-control" type="text" placeholder="Id operador" value={frameworkSeleccionado && frameworkSeleccionado.id_operador} autoComplete="off" readOnly />
                </div>

                

                <div class="accordion-body">
                <input name="name_operador" className="form-control" type="text" placeholder="nombre operador" value={frameworkSeleccionado && frameworkSeleccionado.name_operador} autoComplete="off"  readOnly/>
                </div>

                
           

              </div>
            </div>
            
            
          </div>
     
     

   </div>
  
   
   </form>
<br/>
<button className="btn btn-danger w-100 " onClick={()=>peticionPut()}><i class="fa-solid fa-pen-to-square"></i> Anular Recarga</button>{" "}
<br/><br/> <button className="btn btn-info w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
   </div>
 </ModalBody>
 <ModalFooter>
  


 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
 <ModalBody>
   <div className="form-group">
   <form className="fs-13px" method="post" action="https://parqueoenvia.com/apiParqueoenvia/operadores_img/index.php" target="_parent" name="reset" encType='multipart/form-data'  noValidate>
   <div className="row">

        <div class="col-12 ">
        <br/>
     <center><label>VALOR A PAGAR HOY:</label></center>
     <br/>
     
     <input type="text" className="form-control" placeholder='0' name="valor_t2"  id="valor_t2" onChange={handleChange} value={totalsuma} 

   
     style={{fontSize:"30px", textAlign:"center",height: "80px", maxHeight:"80px"}} 
     readOnly/>
     
     </div>   



            <div class="col-12">
   
    <br/>
    
   {/*  <div class="form-group">
        <br/>
                <label class="form-label" for="customFile">Sube copia del pago</label>
                <input class="form-control border-0 form-control-clicked" id="customFile" type="file"
                onChange={(e) => setImagen2(e.target.files[0])} />
              </div> */}

<div class="form-group">
        <br/>
                <label class="form-label" for="customFile">Sube copia del pago</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto" name="txtFoto" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div>
            

     </div>

   

     
  <br/>
   <button className="btn btn-success w-100" type="submit" onClick={loadData} disabled={loading}> PROCESAR </button><br/><br/>

     </div>
     <br/>

     <label>Operador: {theUser.name}</label>

     <br/>
         <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1" style={{display:"none"}} >
          
  
            <div class="accordion-item">
              <div class="accordion-header" id="accordionTwo">
                <h6 class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordionStyleTwo" aria-expanded="false" aria-controls="accordionStyleTwo">Operador<i class="bi bi-chevron-down"></i></h6>
              </div>
              <div class="accordion-collapse collapse" id="accordionStyleTwo" aria-labelledby="accordionTwo" data-bs-parent="#accordionStyle1" >
                <div class="accordion-body">
                <input name="id_operador" id="operador" className="form-control" type="text" placeholder="Id operador" value={theUser.id} autoComplete="off" readOnly />
                <input name="name" id="name" className="form-control" type="text" placeholder="Nombre" value={theUser.name} autoComplete="off" readOnly />
                <input name="documento" id="documento" className="form-control" type="text" placeholder="documento" value={theUser.documento} autoComplete="off" readOnly />
                <input name="celular" id="celular" className="form-control" type="text" placeholder="celular" value={theUser.celular} autoComplete="off" readOnly />
                <input name="country2" id="country2" className="form-control" type="text" placeholder="zona" value={theUser.country2} autoComplete="off" readOnly />
                <input name="country" id="country" className="form-control" type="text" placeholder="sub-zona" value={theUser.country} autoComplete="off" readOnly />
                </div>

                </div>
                </div>

                </div>

   </form>  
   </div>
 </ModalBody>
 <ModalFooter>
 {/*<button  className="btn btn-primary btn-lg" onClick={()=>peticionPost()} >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wallet2" viewBox="0 0 16 16"
   >
  <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
</svg> PROCESAR
            </button> {" "}   */}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 
   

 </ModalFooter>
</Modal>




        </div>
  )
}

else if(showLogin){
    return"";
}

}

export default Anular_recarga