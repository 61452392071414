import React from 'react'

function Routes() {


    return <></>
  }


  Routes.global = {
    url_login: 'https://parqueoenvia.com/apiParqueoenvia/usuarios/'
  };

  
  export default Routes;
