import React, {useContext, useState,useEffect} from 'react'
import {MyContext} from './MyContext';

import Logo from '../../components/assets/login/logo.png';


import Car from '../../components/assets/login/car.gif';

import '../../App.css';


function Login(){

    const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);

useEffect(() => {
  window.addEventListener("beforeinstallprompt", (event) => {
    // Prevent the mini-infobar from appearing on mobile.
    event.preventDefault();
    console.log("👍", "beforeinstallprompt", event);
    // Stash the event so it can be triggered later.
    window.deferredPrompt = event;
    // Remove the 'hidden' class from the install button container.
    setIsReadyForInstall(true);
  });
}, []);

async function downloadApp() {
    console.log("👍", "butInstall-clicked");
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      console.log("oops, no prompt event guardado en window");
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    console.log("👍", "userChoice", result);
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }



    const [loading, setLoading] = useState(false);

    

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }
    const [onehabilita, setOnehabilita] = useState();
    const [twohabilita, setTwohabilita] = useState(true);
    
    const [crear_user, setCrear_usuario] = useState("VOLVER");

    const {toggleNav,loginUser,isLoggedIn,registerUser} = useContext(MyContext);

    const initialState = {
        userInfo:{
            name:'',
            celular:'',
            documento:'',
            email:'',
            clave:'',
            tipo_usuario:'cliente',

        },
        errorMsg_login:'',
        successMsg_login:'',
        errorMsg_crear:'',
        successMsg_crear:'',
    }


    const crear_usuario= (e) => {
        // console.log(e.target.value) // si || no
        setCrear_usuario(e.target.value)
      }

    const [state,setState] = useState(initialState);

    // On change input value (email & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm_login = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        if(data.success && data.token){
            setState({
                ...initialState,
            });
            localStorage.setItem('loginToken', data.token);
            await isLoggedIn();
        }
        else{
            setState({
                ...state,
                successMsg_login:'',
                errorMsg_login:data.message
            });
        }
    }


    const submitForm = async (event) => {
        event.preventDefault();
        const data = await registerUser(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg_crear:data.message,
               
            });
        }
        else{
            setState({
                ...state,
                successMsg_crear:'',
                errorMsg_crear:data.message
            });
        }
    }

    // Show Message on Error or Success
    let successMsg_login = '';
    let errorMsg_login = '';
    if(state.errorMsg_login){
        errorMsg_login= <div className="error-msg">{state.errorMsg_login}</div>;
    }
    if(state.successMsg_login){
        successMsg_login = <div className="success-msg">{state.successMsg_login}</div>;
    }


     // Show Message on Error or Success
     let successMsg_crear = '';
     let errorMsg_crear = '';
     if(state.errorMsg_crear){
         errorMsg_crear= <div className="error-msg">{state.errorMsg_crear}</div>;
     }
     if(state.successMsg_crear){
         successMsg_crear = <div className="success-msg">{state.successMsg_crear}</div>;
     }



    return(
<>
{/*<div className="login-back-button">
    <a href="element-hero-blocks.html">
        <svg className="bi bi-arrow-left-short" width="32" height="32"  viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
    </svg></a></div> */}

        <div className="login-wrapper d-flex align-items-center justify-content-center">
      {/*<div className="custom-container">*/}
      <div style={{maxWidth:"350px"}}>
        <div className="text-center px-4">
         { /* <img className="login-intro-img" src="img\bg-img\36.png" alt="" /> */}

         
{isReadyForInstall && <center><button className='btn btn-info' onClick={downloadApp}>Descarga parqueoenvia</button> </center>} <br/>

<img className="login-intro-img" src={Logo} style={{width: "160px", alignContent:"center"}}  />


         
         </div>
       
        <div className="register-form mt-4">
      
        {crear_user === "VOLVER" && (
          <form onSubmit={submitForm_login}>
            <div className="form-group">
              <input name="email" type="text" placeholder="Email"  className="form-control"
              value={state.userInfo.email} onChange={onChangeValue} autoComplete="off" />
            </div>
            <div className="form-group position-relative">
              <input  name="clave" className="form-control" id="psw-input" type="password" placeholder="Contraseña"
              value={state.userInfo.clave} onChange={onChangeValue} autoComplete="off"  />

              <div className="position-absolute" id="password-visibility"></div>
            </div>
           <button className="btn btn-success w-100" type="submit"> INGRESAR </button><br/><br/>

      
      { {errorMsg_login} != "" ?<p style={{color:"#842029"}}>{errorMsg_login} {successMsg_login}</p> 
           : ""
          
      } 


      <div className="row"> 
    
           <div class="col-6">
            <input className="btn btn-info w-40"  value="REGISTRARSE"  onClick={crear_usuario} style={{width: "180px"}}  />  &nbsp;
           </div>
           <div class="col-6" >
            <input className="btn btn-light w-40" value="RECUPERAR CLAVE" onClick={crear_usuario} style={{width: "165px"}}/> 
            </div>
           
            </div>  
                  
         
          </form>

)}

          {crear_user === "REGISTRARSE" && (
                   <form onSubmit={submitForm}>
                    <div className="form-group">
                     <input name="name" className="form-control" type="text" placeholder="Nombre completo"
                    required  value={state.userInfo.name} onChange={onChangeValue} autoComplete="off" />
                   </div>
                   <div className="form-group">
                     <input name="celular" className="form-control" type="number" placeholder="Celular"
                    required  value={state.userInfo.celular} onChange={onChangeValue} autoComplete="off" />
                   </div>
                   <div className="form-group">
                     <input name="documento" className="form-control" type="number" placeholder="Documento"
                    required  value={state.userInfo.documento} onChange={onChangeValue} autoComplete="off" />
                   </div>
                   <div className="form-group">
                     <input name="email" className="form-control" type="email" placeholder="Email"
                    required  value={state.userInfo.email} onChange={onChangeValue} autoComplete="off" />
                   </div>
                   <div className="form-group position-relative">
                     <input  name="clave" className="form-control" id="psw-input" type="password" placeholder="Contraseña"
                    required  value={state.userInfo.clave} onChange={onChangeValue} autoComplete="off"  />
       
                     <div className="position-absolute" id="password-visibility"></div>
                   </div>

                   <div class="form-check mb-3">
              <input class="form-check-input" id="checkedCheckbox" type="checkbox" 
              onChange={() => setTwohabilita(!twohabilita)}  style={{backgroundColor:"#037abc"}}/>
              <label class="form-check-label text-muted fw-normal" for="checkedCheckbox" >Acepto todas las políticas</label>
            </div>

          

                  <button className="btn btn-info w-100" type="submit" disabled={onehabilita || twohabilita }> REGISTRAR </button><br/><br/>
       
             
             { {errorMsg_crear} != "" ?<p style={{color:"#842029"}}>{errorMsg_crear} {successMsg_crear}</p> 
                  : ""
                 
             } 
       
      

             <div className="row"> 
            
                  <div class="col-6"> 
                   <input className="btn btn-warning w-40"  value="VOLVER"  onClick={crear_usuario} style={{width: "180px"}}  />  &nbsp;
                   
                  </div>
                  <div class="col-6" >
                  <input className="btn btn-light w-40"  value="RECUPERAR CLAVE" onClick={crear_usuario} style={{width: "165px"}}/>  
                   </div>

                   
                  
                   </div> 

                   
                         
                
                 </form>
            )}



{crear_user === "RECUPERAR CLAVE" && (
           <form className="fs-13px" method="post" action="https://parqueoenvia.com/email/app.php" target="_parent" name="reset" noValidate>
            <div className="form-group">
              <input name="email" className="form-control" type="text" placeholder="Email" autoComplete="off" />
            </div>
            
           <button className="btn btn-success w-100" type="submit" onClick={loadData} disabled={loading}> RECUPERAR CLAVE </button><br/><br/>

      
      
          <center> <input className="btn btn-warning w-40"  value="VOLVER"  onClick={crear_usuario} style={{width: "180px"}}  /></center>
     
                  
         
          </form>

)}

        </div>
       
      <div className="login-meta-data text-center">  {/*<a className="stretched-link forgot-password d-block mt-3 mb-1" href="page-forget-password.html">Recuperar contraseña?</a> */} <br/>
         
        </div>

        <img className="login-intro-img" src={Car} style={{borderRadius:"8px"}}
         
         
         />
      </div>
    </div>

</>
      /*  <div className="login-4l">
            <div className="containerl-fluid">
                <div className="rowl login-boxl">
                    <div className="col-lg-6 form-sectionl">
                        <div class="form-innerl">
                            
                                <h1 style={{color:"white"}}>ODÍN INTERFACE</h1>
                            
                            <h3>
                                Inteligencia Artificial
                            </h3>
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                <strong>Hola diego,</strong> En que te puedo ayudar!
                                 
                                {errorMsg}
                                {successMsg}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                            <form onSubmit={submitForm} noValidate>
                                <div class="form-group position-relative clearfix">
                                    <input name="email" type="email" className="form-control"
                                     placeholder="Usuario" aria-label="Email Address"  
                                     value={state.userInfo.email} onChange={onChangeValue} autoComplete="off" />

                                    <div class="login-popover login-popover-abs" data-bs-toggle="popover" data-bs-trigger="hover" title="Solo Usuarios Creados" data-bs-content="Usuarios creados por ODÍN INTERFACE">
                                        <i class="fa fa-info-circle"></i>
                                    </div>
                                </div>
                                <div class="form-group clearfix position-relative password-wrapper">
                                    <input name="clave" type="password" className="form-control"
                                     autocomplete="off" placeholder="Contraseña" aria-label="Password"
                                     value={state.userInfo.clave} onChange={onChangeValue} />
                                    <i class="fa fa-eye password-indicator" ></i>
                                </div>
                                <div class="form-group mb-0 clearfix">
                                    <button type="submit" class="btn btn-lg btn-primary btn-theme">Ingresar</button>
                                    <a href="#" class="forgot-password float-end">Recuperar clave</a>


                                   
                                    
                                </div>
                                <div class="extra-login clearfix">
                                    <span>Todos los derechos reservados - Giovanny Chavez Marin</span>
                                </div>
                                <div class="clearfix"></div>
                                
                            </form>
                            <div class="clearfix"></div>
                           
                        </div>
                    </div>
                    <div class="col-lg-6 bg-img clip-home h-100"></div>
                </div>
            </div>
        </div>

*/


    );
}

export default Login;