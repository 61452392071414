import React, {useState,useContext,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {MyContext} from '../../login/MyContext';

import axios from 'axios';

const No_registrados = () => {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    
    const [country2, setName] = useState([]);
    const [country, setCountry] = useState([]);

    const peticionGet =async() =>{
        
        console.log("nombre" + global.miID)

       
       
      await axios.get(`https://parqueoenvia.com/apiParqueoenvia/zonas_app/index.php?id=${global.miID}`)
      .then((res) => {
         setName(res.data.country2);
         setCountry(res.data.country);
       // console.log("nombrex" + res.data.celular);
      }).catch(error=>{
        console.log(error);
      })
    
    }


    useEffect(()=>{
    

      const interval = setInterval(() => {
        
         peticionGet();
         
       }, 2000);
   
     return () => setInterval(interval);
   
     },[])
   
     

   

  

    if(isAuth)
       {
  return (
    <div class="container" style={{marginTop:"-45px"}}>
          <div class="row g-2">   
         
          

         
          { theUser.tipo_usuario =="operador"
     ?
            <div class="col-12">

<center><div class="contact-info bg-warning">
                  <p class="mb-0 text-truncate" style={{color:"black"}}>USUARIOS NO REGISTRADOS Y REGISTRADOS </p>
                </div>

                </center> 
             
              <div class="card team-member-card shadow">
              
                <div class="card-body">
                <Link to="/No_registrados_usuarios" className="menu-link" >
                  <div class="team-member-img shadow-sm" style={{marginBottom:"0rem", maxWidth:"10rem"}} ><img src="img\parqueo\desocupada.jpg" alt="" /></div>
                  </Link>
                
                </div>
               
               
                <div class="contact-info bg-info">
                  <p class="mb-0 text-truncate">BAHÍA PARA ENTREGAR <img src="img\gif\cargando.gif" width="15px" alt="" /></p>
                </div>
              </div>
            </div>
          : ""
        } 


{ theUser.tipo_usuario =="operador"
     ?
            <div class="col-12">
              <div class="card team-member-card shadow">
                <div class="card-body">
                <Link to="/No_registrados_manual" className="menu-link" >
                  <div class="team-member-img shadow-sm" style={{marginBottom:"0rem", maxWidth:"10rem"}} ><img src="img\parqueo\manual.jpg" alt="" /></div>
                  </Link>
                
                </div>
               
               
                <div class="contact-info bg-success">
                  <p class="mb-0 text-truncate">BAHÍA ENTREGADA MANUAL <img src="img\gif\cargando.gif" width="15px" alt="" /></p>
                </div>
              </div>
            </div>
          : ""
        } 


{ theUser.tipo_usuario =="operador"
     ?
            <div class="col-12">
              <div class="card team-member-card shadow">
                <div class="card-body">
                <Link to="/No_registrados_revertir" className="menu-link" >
                  <div class="team-member-img shadow-sm" style={{marginBottom:"0rem", maxWidth:"10rem"}} ><img src="img\parqueo\revertir.jpg" alt="" /></div>
                  </Link>
                
                </div>
               
               
                <div class="contact-info bg-success">
                  <p class="mb-0 text-truncate">REVERTIR ENTREGA BAHÍA <img src="img\gif\cargando.gif" width="15px" alt="" /></p>
                </div>
              </div>
            </div>
          : ""
        } 
            
            
           
            { theUser.tipo_usuario =="operador"
            ?
            <div class="col-12">
              <div class="card team-member-card shadow">
                <div class="card-body">
                <Link to="/No_registrados_autoridades" className="menu-link" >
                  <div class="team-member-img shadow-sm" style={{marginBottom:"0rem", maxWidth:"10rem"}} ><img src="img\parqueo\grua.jpg" alt="" /></div>
                  </Link>
                
                </div>

               
                <div class="contact-info bg-danger">
                  <p class="mb-0 text-truncate">REPORTAR AUTORIDADES <img src="img\gif\cargando.gif" width="15px" alt="" /></p>
                </div>
              </div>
            </div>
           : ""
          } 
            
           
            

          
            
          </div>

         
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
  )
}

else if(showLogin){
    return"";
}

}

export default No_registrados