import React, {useState,useEffect,useContext} from 'react';
import axios from 'axios';

import {MyContext} from '../../login/MyContext';



const Recarga = () => {
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    //suma
    const [number, setNumber] = useState({valor: 0})
    const [suma, setSuma] = useState()
  
    useEffect(()=>{
      const { valor } = number
      setSuma(Number(valor) * global.valorhora)
    },[number])
  
    const handleInput = (event) => {
      const { name, value } = event.target
      setNumber({...number, [name]: value})
    }

    ////////////////////////////////////////////////////////

    const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }
   

    const [valorhora, setTarifas] = useState([]);

  const peticionGet_tarifa =async() =>{
    await axios.get(`https://www.parqueoenvia.com/apiParqueoenvia/tarifas/index.php/`)
    .then((res) => {
       setTarifas(res.data.valorhora);
    }).catch(error=>{
      console.log(error);
    })
  
  }

  /*  const peticionGet =async() =>{
        
        console.log("nombre" + global.miID)
       // let miID=global.miID
       

      await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/index.php?id=${global.miID}`)
      .then((res) => {
         setName(res.data.saldo);
      }).catch(error=>{
        console.log(error);
      })
    
    }*/

    

  
   

useEffect(()=>{
    

        const interval = setInterval(() => {
          
           peticionGet_tarifa();
           
         }, 1000);
     
       return () => setInterval(interval);
     
       },[])
       
  
       if(isAuth)
       {
  return (
       <div class="container direction-rtl" style={{marginTop:"-45px"}}>
       
    <div class="card timeline-card bg-success" >
          <div class="card-body">
          <form className="fs-13px" method="post" action="https://parqueoenvia.com/email/recarga_rapida.php" target="_parent" name="reset" noValidate>
           
             <div class="row">
{console.log(global.valorhora = valorhora)}
             <div class="form-group col-7">
                <label class="form-label" for="defaultSelect" >Tiempo en vahía</label>
                <select class="form-select form-control-clicked" id="valor" name="valor" aria-label="Default select example"
                 onChange={handleInput} value={number.valor}>
                  <option value="0" selected="">Seleccione</option>
                  <option value="1">1 hora</option>
                  <option value="2">2 horas</option>
                  <option value="3">3 horas</option>
                  <option value="4">4 horas</option>
                  <option value="5">5 horas</option>
                </select>
              </div>

              <div className="form-group col-5">
            <label class="form-label" for="defaultSelect">Valor a cobrar</label>
              <input name="valor_t" className="form-control" type="number" placeholder="" autoComplete="off" value={suma} readOnly />
            </div>
           
       
            </div>

            <div class="form-group">
                <label class="form-label" for="defaultSelect">Tipo vehículo</label>
                <select class="form-select form-control-clicked" id="t_vehiculo" name="t_vehiculo" aria-label="Default select example">
                  <option value="Carro" selected="">Carro</option>
                  <option value="Moto">Moto</option>
                </select>
              </div>

            <div className="form-group">
              <input name="placa" className="form-control" type="text" placeholder="Placa vehículo" autoComplete="off" />
            </div>

            <p>------------- Datos Usuario  -------------</p>

            <div className="form-group">
              <input name="email" className="form-control" type="email" placeholder="Email" autoComplete="off" />
            </div>

            <div class="form-group">
                <label class="form-label" for="defaultSelect">Tipo documento</label>
                <select class="form-select form-control-clicked" id="t_documento" name="t_documento" aria-label="Default select example">
                  <option value="C.C" selected="">C.C</option>
                  <option value="Pasaporte">Pasaporte</option>
                </select>
              </div>

            <div className="form-group">
              <input name="documento" className="form-control" type="number" placeholder="N° identificación" autoComplete="off" />
            </div>

            <div className="form-group">
              <input name="celular" className="form-control" type="number" placeholder="Celular" autoComplete="off" />
            </div>

            <div className="form-group">
              <input name="nombre" className="form-control" type="text" placeholder="Nombres completos" autoComplete="off" />
            </div>

            
            
           <button className="btn btn-success w-100" type="submit" onClick={loadData} disabled={loading}> GENERAR RECARGA </button><br/><br/>
          
          

           <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1">
            
    
              <div class="accordion-item">
                <div class="accordion-header" id="accordionTwo">
                  <h6 class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordionStyleTwo" aria-expanded="false" aria-controls="accordionStyleTwo">Operador<i class="bi bi-chevron-down"></i></h6>
                </div>
                <div class="accordion-collapse collapse" id="accordionStyleTwo" aria-labelledby="accordionTwo" data-bs-parent="#accordionStyle1" >
                  <div class="accordion-body">
                  <input name="id_operador" className="form-control" type="text" placeholder="Id operador" value={theUser.id} autoComplete="off" readOnly />
                  </div>

                  <div class="accordion-body">
                  <input name="name_operador" className="form-control" type="text" placeholder="Nombres completos" value={theUser.name} autoComplete="off"  readOnly/>
                  </div>

                  <div class="accordion-body">
                  <input name="celular_operador" className="form-control" type="text" placeholder="Celular" value={theUser.celular} autoComplete="off"  readOnly/>
                  </div>

                  <div class="accordion-body">
                  <input name="documento_operador" className="form-control" type="text" placeholder="Documento" value={theUser.documento} autoComplete="off"  readOnly/>
                  </div>
                </div>
              </div>
              
              
            </div>
     
                  
         
          </form>
          <br/>
          <br/>

                  </div>
        </div>

        </div>
  )
}

else if(showLogin){
    return"";
}

}

export default Recarga