import React, {useState,useEffect,useContext} from 'react';
import axios from 'axios';

//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { AddBox, ArrowDownward } from "@material-ui/icons";

import {MyContext} from '../../login/MyContext';

//import SearchIcon from '@mui/icons-material/Search';
//import SearchIcon from '@material-ui/core/SearchIcon';



const Historial = () => {
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    const [data, setData]=useState([]);
    const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
        id:'',
        name:'',
        saldo:'',
      });
   

      const [modalInsertar, setModalInsertar]=useState(false);
      const [modalEditar, setModalEditar]=useState(false);

    const [saldo, setName] = useState([]);

    const peticionGet =async() =>{
        // await axios.get(baseUrlAd)
         //await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/historial.php?idPuntoVenta=${global.id}`)
         await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/historial.php?idCliente=${global.id}`)
         .then(response=>{
          setData(response.data);
         }).catch(error=>{
           console.log(error);
         })
     
       }



    /*const peticionGet =async() =>{
        await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/index.php?id=${global.miID}`)
        .then(response=>{
         setData(response.data);
        }).catch(error=>{
          console.log(error);
        })
    
      }*/


     
      const handleChange=e=>{
        const {name, value}=e.target;
        setFrameworkSeleccionado((prevState)=>({
          ...prevState,
          [name]: value
        }))
       console.log(frameworkSeleccionado);
      }
    
      const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
      }
   

useEffect(()=>{
    

        const interval = setInterval(() => {
          
           peticionGet();
           
         }, 1000);
     
       return () => setInterval(interval);
     
       },[])


       const seleccionarFramework=(framework, caso)=>{
        setFrameworkSeleccionado(framework);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEditar()
    
    
      }


       const columnas =[    
       
        {
          title:"FECHA",
          field: "tiempo"
        },

        {
            title:"VALOR_RECARGA",
            field: "saldoActual"
          },

          {
            title:"ANULADA",
            field: "anulada"
          },

          {
            title:"FECHA_ANULACIÓN",
            field: "fechaValorAnulado"
          },
    
        
       
    
    
        
        
      ];
    
  
       if(isAuth)
       {
  return (
       <div class="container direction-rtl" style={{marginTop:"-45px"}}>
       
   {/* <div class="card timeline-card bg-success" >
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div class="timeline-text mb-2"><span class="badge mb-2 rounded-pill">HISTORIAL DE RECARGAS</span>
                <h5>${saldo} COP</h5>
              </div>
              <div class="timeline-icon mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
  <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
  <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
  <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
</svg>
              </div>
            </div>
                  </div>
        </div>
  */}


        <MaterialTable

     
columns={columnas}
data={data}
title= { <><i class="fas fa-hands-helping fa-2x"></i>Tabla&nbsp;</> }
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'paid',
        tooltip: 'Ver Resumen',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
  
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>
<br/>
<br/>
<br/>

<Modal isOpen={modalEditar}>
 {/*<ModalHeader  style={{backgroundColor:"#F4F4F4",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">MI RECARGA</font></ModalHeader>*/}
 <ModalBody>
   <div className="form-group ">
   <div className="row">

 <div class="col-4 ">
     <label>Recarga:</label>
     <br/>
     <input type="number" placeholder="0" className="form-control " name="saldoActual" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.saldoActual} readOnly  />
     <br/>
     </div>

  

   
     <div class="col-8">
     <label>Fecha Recarga:</label>
     <br/>
     <input type="text" className="form-control" name="tiempo" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.tiempo}  readOnly/>
     <br/>
     </div>

   
     <div class="col-12 ">
      <center><p>------ Datos Punto de Venta -------</p></center>
     </div>
     <div class="col-12">
     <label>Dirección puntoVenta:</label>
     <br/>
     <textarea type="text" className="form-control" name="direccion_puntoVenta" cols="3" rows="5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.direccion_puntoVenta} readOnly/>
     <br/>
     </div>

     <div class="col-12 ">
     <label>Usuario puntoVenta:</label>
     <br/>
     <input type="text" className="form-control" name="name_puntoVenta" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.name_puntoVenta} readOnly/>
     
     </div>

   
     
     

   </div>
   </div>
 </ModalBody>
 <ModalFooter>
  
<button className="btn btn-info" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Aceptar</button>
 </ModalFooter>
</Modal>

        </div>
  )
}

else if(showLogin){
    return"";
}

}

export default Historial