import React, {useContext,useState} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';

const Principal = () => {
  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

  if(isAuth)
  {
  return (
   

<div class="container direction-rtl" style={{marginTop:"-45px"}}>
        <div class="card mb-3">
          <div class="card-body">
            <div class="row g-3">


            { theUser.tipo_usuario =="cliente"
     ?
              <div class="col-4">
              <Link to="/Saldos" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\dinero.png" alt="" /></div>
                  <p class="mb-0">Saldo</p>
                </div>
                </Link>
              </div>
            
: ""
} 



{ theUser.tipo_usuario =="cliente"
                ?
              <div class="col-4">
                <Link to="/Zonas_clientes" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\vahia.png" alt="" /></div>
                  <p class="mb-0">Bahías</p>
                </div>
                </Link>
              </div>
: ""
} 

{ theUser.tipo_usuario =="cliente_"
                ?
              <div class="col-4">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\puntos.png" alt="" /></div>
                  <p class="mb-0">P. Recargas</p>
                </div>
              </div>
: ""
} 



 {/*
              <div class="col-4">
              <Link to="/Recarga" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\rapida.png" alt="" /></div>
                  <p class="mb-0">Recarga Rápida</p>
                </div>
                </Link>
              </div>

*/}

{ theUser.tipo_usuario =="operador"
     ?
              <div class="col-4">
              <Link to="/Zona_operador" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\rapida.png" alt="" /></div>
                  <p class="mb-0">Recarga Rápida</p>
                </div>
                </Link>
              </div>
            
: ""
} 

{ theUser.tipo_usuario =="operador"
     ?
              <div class="col-4">
              <Link to="/Anular_recarga" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\ticket_null.png" alt="" /></div>
                  <p class="mb-0">Anular Recarga</p>
                </div>
                </Link>
              </div>
            
: ""
} 


{ theUser.tipo_usuario =="operador"
     ?
              <div class="col-4">
              <Link to="/Liberar_bahias" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\liberar.png" alt="" /></div>
                  <p class="mb-0">Liberar Bahías</p>
                </div>
                </Link>
              </div>
            
: ""
} 

{ theUser.tipo_usuario =="operador"
     ?
              <div class="col-4">
              <Link to="/Restaurar_bahias" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\restaurar.png" alt="" /></div>
                  <p class="mb-0">Restaurar Bahías</p>
                </div>
                </Link>
              </div>
            
: ""
} 


{ theUser.tipo_usuario =="operador"
     ?
              <div class="col-4">
              <Link to="/No_registrados" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\no_registrado.png" alt="" /></div>
                  <p class="mb-0">Usuarios</p>
                </div>
                </Link>
              </div>
            
: ""
} 


{ theUser.tipo_usuario =="operador"
     ?
              <div class="col-4">
              <Link to="/Arqueo" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\caja.png" alt="" /></div>
                  <p class="mb-0">Arqueo de Caja</p>
                </div>
                </Link>
              </div>
            
: ""
} 


{ theUser.tipo_usuario =="operador"
     ?
              <div class="col-4">
              <Link to="/Recogidos_autoridad" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\prohibido.png" alt="" /></div>
                  <p class="mb-0">Vehículos recogidos</p>
                  <br/>
                  <br/>
                </div>
                </Link>
              </div>
            
: ""
}


{ theUser.tipo_usuario =="autoridad"
     ?
              <div class="col-4">
              <Link to="/Recoger" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\migrua.png" alt="" /></div>
                  <p class="mb-0">Recoger vehículos</p>
                </div>
                </Link>
              </div>
            
: ""
} 

{ theUser.tipo_usuario =="autoridad"
     ?
              <div class="col-4">
              <Link to="/Recogidos" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\prohibido.png" alt="" /></div>
                  <p class="mb-0">Vehículos recogidos</p>
                </div>
                </Link>
              </div>
            
: ""
} 


{ theUser.tipo_usuario =="cliente"
     ?
              <div class="col-4">
              <Link to="/Patios" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\prohibido.png" alt="" /></div>
                  <p class="mb-0">Vehículos en patios</p>
                </div>
                </Link>
              </div>
            
: ""
} 



{ theUser.tipo_usuario =="supervisor"
     ?
              <div class="col-4">
              <Link to="/Supervisar" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\transito.png" alt="" /></div>
                  <p class="mb-0">Supervisar zonas</p>
                </div>
                </Link>
              </div>
            
: ""
} 


{ theUser.tipo_usuario =="supervisor"
     ?
              <div class="col-4">
              <Link to="/Novedades" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\noti.png" alt="" /></div>
                  <p class="mb-0">Novedades registradas</p>
                </div>
                </Link>
              </div>
            
: ""
} 


{ theUser.tipo_usuario =="visor"
     ?
              <div class="col-4">
              <Link to="/Ganancias_totales" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\ganancias_t.png" alt="" /></div>
                  <p class="mb-0">Recargas utilizadas</p>
                </div>
                </Link>
              </div>
            
: ""
} 


{ theUser.tipo_usuario =="visor"
     ?
              <div class="col-4">
              <Link to="/Recargas_utilizadas_zonas" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\ganancias_t_z.png" alt="" /></div>
                  <p class="mb-0">Recargas utilizadas por zona</p>
                </div>
                </Link>
              </div>
            
: ""
} 

{ theUser.tipo_usuario =="visor"
     ?
              <div class="col-4">
              <Link to="/Recargas_utilizadas_dia" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\ganancias_d.png" alt="" /></div>
                  <p class="mb-0">Recargas utilizadas del día</p>
                </div>
                </Link>
              </div>
            
: ""
} 


{ theUser.tipo_usuario =="visor"
     ?
              <div class="col-4">
              <Link to="/Recargas_dia_zona" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\ganancias_d_z.png" alt="" /></div>
                  <p class="mb-0">Recargas utilizadas del día - zona</p>
                </div>
                </Link>
              </div>
            
: ""
} 


{ theUser.tipo_usuario =="visor"
     ?
              <div class="col-4">
              <Link to="/Recargas_pv" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\pv.png" alt="" /></div>
                  <p class="mb-0">Recargas puntos de venta</p>
                </div>
                </Link>
              </div>
            
: ""
} 


{ theUser.tipo_usuario =="visor"
     ?
              <div class="col-4">
              <Link to="/Busqueda_item" className="menu-link">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><img src="img\demo-img\puntos.png" alt="" /></div>
                  <p class="mb-0">Busqueda personalizada</p>
                </div>
                </Link>
              </div>
            
: ""
} 







  
              

              
            </div>
          </div>
        </div>


      


      </div>
     
  )
}

else if(showLogin){
    return"";
}

}

export default Principal