import React,{useState} from 'react'
import ReactAudioPlayer from 'react-audio-player';



const SoundAutoridad = () => {



  const [sound, setSound] = useState(true);



  return (
    <>


<ReactAudioPlayer
  src="img\demo-img\poli.mp3"
 autoPlay={sound}
 
 // onPlay={setSound}
  controls
  allow
  loop="true"
/>

</>
  )
}

export default SoundAutoridad