import React, {useState,useEffect,useContext} from 'react';
import axios from 'axios';

import {MyContext} from '../../login/MyContext';



const Saldos = () => {
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;
   

    const [saldo, setName] = useState([]);

    const peticionGet =async() =>{
        
        console.log("nombre" + global.miID)
       // let miID=global.miID
       

      await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/index.php?id=${global.miID}`)
      .then((res) => {
         setName(res.data.saldo);
      }).catch(error=>{
        console.log(error);
      })
    
    }

    
   

useEffect(()=>{
    

        const interval = setInterval(() => {
          
           peticionGet();
           
         }, 1000);
     
       return () => setInterval(interval);
     
       },[])
       
  
       if(isAuth)
       {
  return (
       <div class="container direction-rtl" style={{marginTop:"-45px"}}>
       
    <div class="card timeline-card bg-success" >
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div class="timeline-text mb-2"><span class="badge mb-2 rounded-pill">MI SALDO</span>
                <h5>${saldo} COP</h5>
              </div>
              <div class="timeline-icon mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
  <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
  <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
  <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
</svg>
              </div>
            </div>
                  </div>
        </div>

        </div>
  )
}

else if(showLogin){
    return"";
}

}

export default Saldos