import './App.css';
import {HashRouter as Router, Routes, Route, Navigate} from "react-router-dom";

import MyContextProvider from './components/login/MyContext';
import history from './components/login/history';

import Inicio from './components/views/inicio/Inicio';
import Principal from './components/views/principal/Principal';
import Saldos from './components/views/saldo/Saldos';

import Recarga from './components/views/operadores/Recarga';

import Historial from './components/views/saldo/Historial';
import  Zona_operador  from './components/views/operadores/Zona_operador';
import  Sub_zonas  from './components/views/operadores/Sub_zonas';

import Historial_operadores from './components/views/operadores/Historial_operadores';
import Arqueo from './components/views/operadores/Arqueo';
import Mis_arqueos from './components/views/operadores/Mis_arqueos';

import Anular_recarga from './components/views/operadores/Anular_recarga';

import Liberar_bahias from './components/views/operadores/Liberar_bahias';

import Restaurar_bahias from './components/views/operadores/Restaurar_bahias';

import No_registrados from './components/views/operadores/No_registrados';

import No_registrados_usuarios from './components/views/operadores/No_registrados_usuarios';

import No_registrados_manual from './components/views/operadores/No_registrados_manual';

import No_registrados_revertir from './components/views/operadores/No_registrados_revertir';

import No_registrados_autoridades from './components/views/operadores/No_registrados_autoridades';

import Ganancias_totales from './components/views/visores/Ganancias_totales';


import Zonas_clientes from './components/views/clientes/Zonas_clientes';
import Subzona_1 from './components/views/clientes/Subzona_1';
import Subzona_2 from './components/views/clientes/Subzona_2';
import Subzona_3 from './components/views/clientes/Subzona_3';
import Subzona_4 from './components/views/clientes/Subzona_4';

import Mis_recargas from './components/views/clientes/Mis_recargas';

import Recoger from './components/views/autoridades/Recoger';

import Recogidos from './components/views/autoridades/Recogidos';

import SoundAutoridad from './components/views/autoridades/SoundAutoridad';
import SoundCliente from './components/views/clientes/SoundCliente';

import Recoger_vehiculo from './components/views/clientes/Recoger_vehiculo';

import Patios from './components/views/clientes/Patios';

import Supervisar from './components/views/supervisores/Supervisar';

import Sub_zona1 from './components/views/supervisores/Sub_zona1';

import Sub_zona2 from './components/views/supervisores/Sub_zona2';

import Sub_zona3 from './components/views/supervisores/Sub_zona3';

import Sub_zona4 from './components/views/supervisores/Sub_zona4';

import Novedades from './components/views/supervisores/Novedades';

import Recargas_utilizadas_zonas from './components/views/visores/Recargas_utilizadas_zonas';

import Sub_zona1_visor from './components/views/visores/Sub_zona1_visor';
import Sub_zona2_visor from './components/views/visores/Sub_zona2_visor';
import Sub_zona3_visor from './components/views/visores/Sub_zona3_visor';
import Sub_zona4_visor from './components/views/visores/Sub_zona4_visor';

import Recargas_utilizadas_dia from './components/views/visores/Recargas_utilizadas_dia';

import Recargas_dia_zona from './components/views/visores/Recargas_dia_zona';

import Sub_zona1_visor_dia from './components/views/visores/Sub_zona1_visor_dia';
import Sub_zona2_visor_dia from './components/views/visores/Sub_zona2_visor_dia';
import Sub_zona3_visor_dia from './components/views/visores/Sub_zona3_visor_dia';
import Sub_zona4_visor_dia from './components/views/visores/Sub_zona4_visor_dia';


import Recargas_pv from './components/views/visores/Recargas_pv';

import Busqueda_item from './components/views/visores/Busqueda_item';

import Recogidos_autoridad from './components/views/operadores/Recogidos_autoridad';

function App() {


  return (
    <MyContextProvider>
    <Router>
     <Inicio/>
     <Routes>
     
     <Route history={history}></Route>
     <Route path="/Principal" element = {<Principal/>}></Route>
     <Route path="/Saldos" element = {<Saldos/>}></Route>

     <Route path="/Recarga" element = {<Recarga/>}></Route>
     <Route path="/Zona_operador" element = {<Zona_operador/>}></Route>
     <Route path="/Sub_zonas" element = {<Sub_zonas/>}></Route>

     <Route path="/Historial" element = {<Historial/>}></Route>
     <Route path="/Historial_operadores" element = {<Historial_operadores/>}></Route>

     <Route path="/Arqueo" element = {<Arqueo/>}></Route>
     <Route path="/Mis_arqueos" element = {<Mis_arqueos/>}></Route>

     <Route path="/Anular_recarga" element = {<Anular_recarga/>}></Route>

     <Route path="/Liberar_bahias" element = {<Liberar_bahias/>}></Route>
     <Route path="/Restaurar_bahias" element = {<Restaurar_bahias/>}></Route>

     <Route path="/No_registrados" element = {<No_registrados/>}></Route>

     <Route path="/No_registrados_usuarios" element = {<No_registrados_usuarios/>}></Route>

     <Route path="/No_registrados_manual" element = {<No_registrados_manual/>}></Route>

     <Route path="/No_registrados_revertir" element = {<No_registrados_revertir/>}></Route>

     <Route path="/No_registrados_autoridades" element = {<No_registrados_autoridades/>}></Route>

     <Route path="/Zonas_clientes" element = {<Zonas_clientes/>}></Route>
     <Route path="/Subzona_1" element = {<Subzona_1/>}></Route>
     <Route path="/Subzona_2" element = {<Subzona_2/>}></Route>
     <Route path="/Subzona_3" element = {<Subzona_3/>}></Route>
     <Route path="/Subzona_4" element = {<Subzona_4/>}></Route>

     <Route path="/Mis_recargas" element = {<Mis_recargas/>}></Route>


     <Route path="/Recoger" element = {<Recoger/>}></Route>
     <Route path="/Recogidos" element = {<Recogidos/>}></Route>

     <Route path="/SoundAutoridad" element = {<SoundAutoridad/>}></Route>
     <Route path="/SoundCliente" element = {<SoundCliente/>}></Route>

     <Route path="/Recoger_vehiculo" element = {<Recoger_vehiculo/>}></Route>
     <Route path="/Patios" element = {<Patios/>}></Route>

     <Route path="/Supervisar" element = {<Supervisar/>}></Route>

     <Route path="/Sub_zona1" element = {<Sub_zona1/>}></Route>

     <Route path="/Sub_zona2" element = {<Sub_zona2/>}></Route>

     <Route path="/Sub_zona3" element = {<Sub_zona3/>}></Route>

     <Route path="/Sub_zona4" element = {<Sub_zona4/>}></Route>

     <Route path="/Novedades" element = {<Novedades/>}></Route>
     <Route path="/Ganancias_totales" element = {<Ganancias_totales/>}></Route>
     <Route path="/Recargas_utilizadas_zonas" element = {<Recargas_utilizadas_zonas/>}></Route>

     <Route path="/Sub_zona1_visor" element = {<Sub_zona1_visor/>}></Route>

     <Route path="/Sub_zona2_visor" element = {<Sub_zona2_visor/>}></Route>

     <Route path="/Sub_zona3_visor" element = {<Sub_zona3_visor/>}></Route>
     <Route path="/Sub_zona4_visor" element = {<Sub_zona4_visor/>}></Route>

     <Route path="/Recargas_utilizadas_dia" element = {<Recargas_utilizadas_dia/>}></Route>

     <Route path="/Recargas_dia_zona" element = {<Recargas_dia_zona/>}></Route>

     <Route path="/Sub_zona1_visor_dia" element = {<Sub_zona1_visor_dia/>}></Route>
     <Route path="/Sub_zona2_visor_dia" element = {<Sub_zona2_visor_dia/>}></Route>
     <Route path="/Sub_zona3_visor_dia" element = {<Sub_zona3_visor_dia/>}></Route>
     <Route path="/Sub_zona4_visor_dia" element = {<Sub_zona4_visor_dia/>}></Route>

     <Route path="/Recargas_pv" element = {<Recargas_pv/>}></Route>
     <Route path="/Busqueda_item" element = {<Busqueda_item/>}></Route>

     <Route path="/Recogidos_autoridad" element = {<Recogidos_autoridad/>}></Route>
     
     <Route path="/" element={<Navigate replace to="/Principal" />}></Route>
     </Routes>
     </Router>
 </MyContextProvider>
  );
}

export default App;
