import React, {useState,useEffect,useContext} from 'react';
import axios from 'axios';

//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { AddBox, ArrowDownward } from "@material-ui/icons";

import {MyContext} from '../../login/MyContext';

//import SearchIcon from '@mui/icons-material/Search';
//import SearchIcon from '@material-ui/core/SearchIcon';



const Sub_zona2_visor_dia = () => {
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    //actualizar tabla
    const tableRef = React.createRef();

    const [data, setData]=useState([]);
    const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
        id:'',
        name:'',
        saldo:'',
      });
   
      const [valuevalor, setValuevalor] = useState("");

      const [modalInsertar, setModalInsertar]=useState(false);
      const [modalEditar, setModalEditar]=useState(false);

     
     
    
     
      //suma
      const [number, setNumber] = useState({valor: 0 })
      const [suma, setSuma] = useState()
  
    useEffect(()=>{
      const { valor } = number
      setSuma(Number(valor) * global.valorhora)
    },[number])
  
    const handleInput = (event) => {
      const { name, value } = event.target
      setNumber({...number, [name]: value})
    }



    const handleVehiculo = (event) => {
      setValuevalor(event.target.value)
      
      const { name, value } = event.target
      setNumber({valor:0})

    }

     
 
       ////////////////////////////////////////////////////////

    const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }



    const [valorhora, setTarifas] = useState([]);

  const peticionGet_tarifa =async() =>{
   // await axios.get(`https://www.parqueoenvia.com/apiParqueoenvia/tarifas/index.php/`)
   await axios.get(`https://www.parqueoenvia.com/apiParqueoenvia/tarifas/index.php?id=${global.one}`)
   
    .then((res) => {
       setTarifas(res.data.valorhora);
    }).catch(error=>{
      console.log(error);
    })
  
  }

  //console.log("revisar " + valorhora)

  const [name_v, setName] = useState([]);
  const [country_vahia, setCountry] = useState([]);
  const [v1, setV1] = useState([]);
  const [v2, setV2] = useState([]);
  const [v3, setV3] = useState([]);
  const [v4, setV4] = useState([]);
  const [v5, setV5] = useState([]);
  const [v6, setV6] = useState([]);
  const [v7, setV7] = useState([]);
  const [v8, setV8] = useState([]);
  const [v9, setV9] = useState([]);
  const [v10, setV10] = useState([]);
  const [v11, setV11] = useState([]);
  const [v12, setV12] = useState([]);
  const [v13, setV13] = useState([]);
  const [v14, setV14] = useState([]);
  const [v15, setV15] = useState([]);
  const [v16, setV16] = useState([]);
  const [v17, setV17] = useState([]);
  const [v18, setV18] = useState([]);
  const [v19, setV19] = useState([]);
  const [v20, setV20] = useState([]);

  const [v1_disponible, set_v1disponible] = useState([]);
  const [v2_disponible, set_v2disponible] = useState([]);
  const [v3_disponible, set_v3disponible] = useState([]);
  const [v4_disponible, set_v4disponible] = useState([]);
  const [v5_disponible, set_v5disponible] = useState([]);
  const [v6_disponible, set_v6disponible] = useState([]);
  const [v7_disponible, set_v7disponible] = useState([]);
  const [v8_disponible, set_v8disponible] = useState([]);
  const [v9_disponible, set_v9disponible] = useState([]);
  const [v10_disponible, set_v10disponible] = useState([]);
  const [v11_disponible, set_v11disponible] = useState([]);
  const [v12_disponible, set_v12disponible] = useState([]);
  const [v13_disponible, set_v13disponible] = useState([]);
  const [v14_disponible, set_v14disponible] = useState([]);
  const [v15_disponible, set_v15disponible] = useState([]);
  const [v16_disponible, set_v16disponible] = useState([]);
  const [v17_disponible, set_v17disponible] = useState([]);
  const [v18_disponible, set_v18disponible] = useState([]);
  const [v19_disponible, set_v19disponible] = useState([]);
  const [v20_disponible, set_v20disponible] = useState([]);



  const [confirmar_zona_v, set_Confirmar_zona_v] = useState([]);

  const peticionGet_vahias =async() =>{
        
    console.log("nombrevahia" + global.user_subzona)

   
  await axios.get(`https://parqueoenvia.com/apiParqueoenvia/zonas_app/vahias.php?country=${global.user_subzona}`)
  .then((res) => {
     setName(res.data.name);
     setCountry(res.data.country);
     set_Confirmar_zona_v(res.data.confirmar_zona);
     setV1(res.data.v1);
     setV2(res.data.v2);
     setV3(res.data.v3);
     setV4(res.data.v4);
     setV5(res.data.v5);
     setV6(res.data.v6);
     setV7(res.data.v7);
     setV8(res.data.v8);
     setV9(res.data.v9);
     setV10(res.data.v10);
     setV11(res.data.v11);
     setV12(res.data.v12);
     setV13(res.data.v13);
     setV14(res.data.v14);
     setV15(res.data.v15);
     setV16(res.data.v16);
     setV17(res.data.v17);
     setV18(res.data.v18);
     setV19(res.data.v19);
     setV20(res.data.v20);
     set_v1disponible(res.data.v1_disponible);
     set_v2disponible(res.data.v2_disponible);
     set_v3disponible(res.data.v3_disponible);
     set_v4disponible(res.data.v4_disponible);
     set_v5disponible(res.data.v5_disponible);
     set_v6disponible(res.data.v6_disponible);
     set_v7disponible(res.data.v7_disponible);
     set_v8disponible(res.data.v8_disponible);
     set_v9disponible(res.data.v9_disponible);
     set_v10disponible(res.data.v10_disponible);
     set_v11disponible(res.data.v11_disponible);
     set_v12disponible(res.data.v12_disponible);
     set_v13disponible(res.data.v13_disponible);
     set_v14disponible(res.data.v14_disponible);
     set_v15disponible(res.data.v15_disponible);
     set_v16disponible(res.data.v16_disponible);
     set_v17disponible(res.data.v17_disponible);
     set_v18disponible(res.data.v18_disponible);
     set_v19disponible(res.data.v19_disponible);
     set_v20disponible(res.data.v20_disponible);
    
   // console.log("nombrex" + res.data.celular);
  }).catch(error=>{
    console.log(error);
  })

}


const [id_unico_subzona, setId_unico_subzona] = useState([]);


const peticionGet_usuarios =async() =>{
        
    console.log("nombrevahia" + global.user_subzona )

   
  await axios.get(`https://parqueoenvia.com/apiParqueoenvia/zonas_app/sub_usuariosApp.php?id=${global.user_id}`)
  .then((res) => {
     setId_unico_subzona(res.data.id_unico_subzona);
  }).catch(error=>{
    console.log(error);
  })

}


{console.log(global.idunico = id_unico_subzona)}


const [v1_vf, setV1_vf] = useState([]);
const [v2_vf, setV2_vf] = useState([]);
const [v3_vf, setV3_vf] = useState([]);
const [v4_vf, setV4_vf] = useState([]);
const [v5_vf, setV5_vf] = useState([]);
const [v6_vf, setV6_vf] = useState([]);
const [v7_vf, setV7_vf] = useState([]);
const [v8_vf, setV8_vf] = useState([]);
const [v9_vf, setV9_vf] = useState([]);
const [v10_vf, setV10_vf] = useState([]);
const [v11_vf, setV11_vf] = useState([]);
const [v12_vf, setV12_vf] = useState([]);
const [v13_vf, setV13_vf] = useState([]);
const [v14_vf, setV14_vf] = useState([]);
const [v15_vf, setV15_vf] = useState([]);
const [v16_vf, setV16_vf] = useState([]);
const [v17_vf, setV17_vf] = useState([]);
const [v18_vf, setV18_vf] = useState([]);
const [v19_vf, setV19_vf] = useState([]);
const [v20_vf, setV20_vf] = useState([]);


const peticionGet_n_vahias =async() =>{
        
    console.log("nombrevahia" + global.user_subzona)

   
  await axios.get(`https://parqueoenvia.com/apiParqueoenvia/zonas_app/n_vahias.php?id=1`)
  .then((res) => {
    setV1_vf(res.data.v1_disponible);
    setV2_vf(res.data.v2_disponible);
    setV3_vf(res.data.v3_disponible);
    setV4_vf(res.data.v4_disponible);
    setV5_vf(res.data.v5_disponible);
    setV6_vf(res.data.v6_disponible);
    setV7_vf(res.data.v7_disponible);
    setV8_vf(res.data.v8_disponible);
    setV9_vf(res.data.v9_disponible);
    setV10_vf(res.data.v10_disponible);
    setV11_vf(res.data.v11_disponible);
    setV12_vf(res.data.v12_disponible);
    setV13_vf(res.data.v13_disponible);
    setV14_vf(res.data.v14_disponible);
    setV15_vf(res.data.v15_disponible);
    setV16_vf(res.data.v16_disponible);
    setV17_vf(res.data.v17_disponible);
    setV18_vf(res.data.v18_disponible);
    setV19_vf(res.data.v19_disponible);
    setV20_vf(res.data.v20_disponible);
     
  }).catch(error=>{
    console.log(error);
  })

}




const [saldo, setNames] = useState([]);

const peticionGet_saldo =async() =>{
    
    console.log("nombre" + global.miID)
   // let miID=global.miID
   

  await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/index.php?id=${global.miID}`)
  .then((res) => {
     setNames(res.data.saldo);
  }).catch(error=>{
    console.log(error);
  })

}


   

    const peticionGet =async() =>{
        // await axios.get(baseUrlAd)
         //await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/historial.php?idPuntoVenta=${global.id}`)
         await axios.get(`https://parqueoenvia.com/apiParqueoenvia/visores/zona2_dia.php`)
         .then(response=>{
          setData(response.data);
         }).catch(error=>{
           console.log(error);
         })
     
       }



    /*const peticionGet =async() =>{
        await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/index.php?id=${global.miID}`)
        .then(response=>{
         setData(response.data);
        }).catch(error=>{
          console.log(error);
        })
    
      }*/


     
      const handleChange=e=>{
        const {name, value}=e.target;
        setFrameworkSeleccionado((prevState)=>({
          ...prevState,
          [name]: value
        }))
       console.log(frameworkSeleccionado);
      }
    
      const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
      }
   

useEffect(()=>{
    

        const interval = setInterval(() => {
          
           peticionGet();
           peticionGet_saldo();
           peticionGet_tarifa();
           peticionGet_vahias(); 
           peticionGet_usuarios();
           peticionGet_n_vahias(); 
           
         }, 1000);
     
       return () => setInterval(interval);
     
       },[])


      /* useEffect(()=>{
           
           peticionGet();
            
       
       },[])*/

   


       const seleccionarFramework=(framework, caso)=>{
        setFrameworkSeleccionado(framework);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEditar()
    
    
      }

       //moneda
       const toMoney = (value) => {
        const money = Number(value);
    
        if (isNaN(money)) {
          return value;
        }
    
        return new Intl.NumberFormat("es-CO", {
          style: "currency",
          currency: "COP"
        }).format(money);
      };


       const columnas =[  
        {
            title:"FECHA",
            field: "expDate"
          },
        {
            title:"VALOR",
            field: "valor_t",
            render: (field) => toMoney(field.valor_t)
          },
        {
          title:"PLACA",
          field: "placa",
         // filtering: false
        },
        {
          title:"VEHÍCULO",
          field: "t_vehiculo",
         // filtering: false
        },       
        {
          title:"ZONA",
          field: "confirmar_zona_v",
         // filtering: false
        },

        {
            title:"SUB-ZONA",
            field: "name_v",
            //lookup:  peticionGet_usuarios,
          },

       
    
    
        
        
      ];
    
  
       if(isAuth && theUser.tipo_usuario =="visor")
       {
  return (
       <div class="container direction-rtl" style={{marginTop:"-45px"}}>
       
   {/* <div class="card timeline-card bg-success" >
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div class="timeline-text mb-2"><span class="badge mb-2 rounded-pill">HISTORIAL DE RECARGAS</span>
                <h5>${saldo} COP</h5>
              </div>
              <div class="timeline-icon mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
  <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
  <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
  <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
</svg>
              </div>
            </div>
                  </div>
        </div>
  */}

{data.length === 0 ? (
    <div class="toast custom-toast-1 toast-info mb-2 fade show" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="5000" data-bs-autohide="false">
    <div class="toast-body">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16">
  <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
  <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
</svg>
    <div class="toast-text ms-3 me-2 ">
        <p class="mb-0 text-white">Esperando...</p>
      </div>
    </div> 
    
  </div>
          
        ) : (
        <MaterialTable

     
columns={columnas}
data={data}
title= { <><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cup-hot" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M.5 6a.5.5 0 0 0-.488.608l1.652 7.434A2.5 2.5 0 0 0 4.104 16h5.792a2.5 2.5 0 0 0 2.44-1.958l.131-.59a3 3 0 0 0 1.3-5.854l.221-.99A.5.5 0 0 0 13.5 6H.5ZM13 12.5a2.01 2.01 0 0 1-.316-.025l.867-3.898A2.001 2.001 0 0 1 13 12.5ZM2.64 13.825 1.123 7h11.754l-1.517 6.825A1.5 1.5 0 0 1 9.896 15H4.104a1.5 1.5 0 0 1-1.464-1.175Z"/>
<path d="m4.4.8-.003.004-.014.019a4.167 4.167 0 0 0-.204.31 2.327 2.327 0 0 0-.141.267c-.026.06-.034.092-.037.103v.004a.593.593 0 0 0 .091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3.31 3.31 0 0 1-.202.388 5.444 5.444 0 0 1-.253.382l-.018.025-.005.008-.002.002A.5.5 0 0 1 3.6 4.2l.003-.004.014-.019a4.149 4.149 0 0 0 .204-.31 2.06 2.06 0 0 0 .141-.267c.026-.06.034-.092.037-.103a.593.593 0 0 0-.09-.252A4.334 4.334 0 0 0 3.6 2.8l-.01-.012a5.099 5.099 0 0 1-.37-.543A1.53 1.53 0 0 1 3 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a5.446 5.446 0 0 1 .253-.382l.025-.035A.5.5 0 0 1 4.4.8Zm3 0-.003.004-.014.019a4.167 4.167 0 0 0-.204.31 2.327 2.327 0 0 0-.141.267c-.026.06-.034.092-.037.103v.004a.593.593 0 0 0 .091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3.31 3.31 0 0 1-.202.388 5.444 5.444 0 0 1-.253.382l-.018.025-.005.008-.002.002A.5.5 0 0 1 6.6 4.2l.003-.004.014-.019a4.149 4.149 0 0 0 .204-.31 2.06 2.06 0 0 0 .141-.267c.026-.06.034-.092.037-.103a.593.593 0 0 0-.09-.252A4.334 4.334 0 0 0 6.6 2.8l-.01-.012a5.099 5.099 0 0 1-.37-.543A1.53 1.53 0 0 1 6 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a5.446 5.446 0 0 1 .253-.382l.025-.035A.5.5 0 0 1 7.4.8Zm3 0-.003.004-.014.019a4.077 4.077 0 0 0-.204.31 2.337 2.337 0 0 0-.141.267c-.026.06-.034.092-.037.103v.004a.593.593 0 0 0 .091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3.198 3.198 0 0 1-.202.388 5.385 5.385 0 0 1-.252.382l-.019.025-.005.008-.002.002A.5.5 0 0 1 9.6 4.2l.003-.004.014-.019a4.149 4.149 0 0 0 .204-.31 2.06 2.06 0 0 0 .141-.267c.026-.06.034-.092.037-.103a.593.593 0 0 0-.09-.252A4.334 4.334 0 0 0 9.6 2.8l-.01-.012a5.099 5.099 0 0 1-.37-.543A1.53 1.53 0 0 1 9 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a5.446 5.446 0 0 1 .253-.382l.025-.035A.5.5 0 0 1 10.4.8Z"/>
</svg></> }
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'accessibility',
        tooltip: 'Ver Resumen',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },

      
    
  
]}

renderSummaryRow={({ column, data }) =>
              column.field === "valor_t"
                ? {
                    value: toMoney(
                      data.reduce((agg, row) => agg + Number(row.valor_t), 0)
                    ),
                    style: { background: "#BFF7D4" }
                  }
                : undefined
            }

/*options={{
  actionsColumnIndex: -1
}}*/
options={{
  //filtering: true,
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}} */



/>

)}
<br/>
<br/>
<br/>

<Modal isOpen={modalEditar}>
 {/*<ModalHeader  style={{backgroundColor:"#F4F4F4",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">MI RECARGA</font></ModalHeader>*/}
 <ModalBody>

 
   <div className="form-group ">

   <form className="fs-13px" method="post" action="https://parqueoenvia.com/apiParqueoenvia/supervidores_img/index.php" target="_parent" encType='multipart/form-data' name="reset" noValidate>
   
 
   <div className="row">

 <div class="col-4 ">
     <label>Recarga:</label>
     <br/>
     <input type="number" placeholder="0" className="form-control " name="valor_t" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.valor_t} readOnly  />
     <br/>
     </div>

  

   
     <div class="col-5">
     <label>Documento:</label>
     <br/>
     <input type="text" className="form-control" name="documento" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.documento}  readOnly/>
     <br/>
     </div>

     <div class="col-3">
     <label>Tiempo:</label>
     <br/>
     <input type="text" className="form-control" name="valor" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.valor}  readOnly/>
     <br/>
     </div>

     <div class="col-12 ">
  
     <label>Email:</label>
     <br/>
     <input type="text" className="form-control" name="email" autofocus onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.email}/>
     
     </div>

     <div class="col-12 ">
     <br/>
     <label>Fecha de ingreso recarga:</label>
     <br/>
     <input type="text" className="form-control" name="expDate" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.expDate} readOnly/>
     
     </div>

     <div class="col-12 ">
     <br/>
     <label>Fecha de terminación recarga:</label>
     <br/>
     <input type="text" className="form-control" name="expDate2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.expDate2} readOnly/>
     
     </div>

     <div class="col-12 ">
     <br/>
     <label>Nombres completos:</label>
     <br/>
     <input type="text" className="form-control" name="nombre" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.nombre} readOnly/>
     
     </div>


     <div class="col-6 ">
     <br/>
     <label>Celular:</label>
     <br/>
     <input type="text" className="form-control" name="celular" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.celular} readOnly/>
     
     </div>

     <div class="col-6 ">
     <br/>
     <label>Vehículo:</label>
     <br/>
     <input type="text" className="form-control" name="t_vehiculo" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.t_vehiculo} readOnly/>
     
     </div>

     <div class="col-6">
     <br/>
     <label>Placa:</label>
     <br/>
     <input type="text" className="form-control" name="placa" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.placa} readOnly/>
     
     </div>




     <div class="col-6 ">
        <br/>
     <label>Zona:</label>
     <br/>
     <input type="text" className="form-control" name="confirmar_zona_v" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.confirmar_zona_v} readOnly/>
     
     </div>


     <div class="col-12 ">
        <br/>
     <label>Sub-zona:</label>
     <br/>
     
     <textarea type="text" className="form-control" name="name_v" cols="3" rows="5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.name_v} readOnly/>
     </div>


     <div class="col-12 ">
        <br/>
     <label>Nombre operador que lo proceso:</label>
     <br/>
     
     <input type="text" className="form-control" name="name_operador" onChange={handleChange} placeholder="El usuario recargo" value={frameworkSeleccionado && frameworkSeleccionado.name_operador} readOnly/>
     </div>


     <div class="col-12 ">
        <br/>
     <label>Documento operador:</label>
     <br/>
     
     <input type="text" className="form-control" name="name_operador"  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.documento_operador} readOnly/>
     </div>



  
     { frameworkSeleccionado.v1 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v1" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v1} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v2 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v2" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v2} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v3 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v3" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v3} readOnly/>
     
     </div>
 : ""
} 



     { frameworkSeleccionado.v4 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v4" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v4} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v5 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v5" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v5} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v6 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v6" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v6} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v7 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v7" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v7} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v8 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v8" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v8} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v9 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v9" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v9} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v10 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v10" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v10} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v11 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v11" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v11} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v12 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v12" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v12} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v13 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v13" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v13} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v14 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v14" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v14} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v15 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v15" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v15} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v16 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v16" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v16} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v17 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v17" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v17} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v18 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v18" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v18} readOnly/>
     
     </div>
 : ""
} 

{ frameworkSeleccionado.v19 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v19" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v19} readOnly/>
     
     </div>
 : ""
} 


{ frameworkSeleccionado.v20 !="" 
     ?
     <div class="col-12 ">
        <br/>
     <label>Bahía:</label>
     <br/>
     <input type="text" className="form-control" name="v20" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.v20} readOnly/>
     
     </div>
 : ""
} 


<div class="col-12 ">
        <br/>
     <label>Novedad:</label>
     <br/>
     <textarea type="text" row={4} className="form-control" name="observaciones_supervisor" onChange={handleChange} 
     value={frameworkSeleccionado && frameworkSeleccionado.observaciones_supervisor} readOnly/>
     
     </div>


     
     <div class="col-12">
   
   <br/>

{/*<div class="form-group">
       <br/>
               <label class="form-label" for="customFile">Sube foto de novedad</label>
               <input class="form-control border-0 form-control-clicked" id="txtFoto" name="txtFoto" type="file"
               accept="image/*"
               onChange={handleChange} />
             </div> */}
    </div>


<br/>
         <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1" style={{display:"none"}} >
          
  
            <div class="accordion-item">
              <div class="accordion-header" id="accordionTwo">
                <h6 class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordionStyleTwo" aria-expanded="false" aria-controls="accordionStyleTwo">Operador<i class="bi bi-chevron-down"></i></h6>
              </div>
              <div class="accordion-collapse collapse" id="accordionStyleTwo" aria-labelledby="accordionTwo" data-bs-parent="#accordionStyle1" >
                <div class="accordion-body">
                <input name="id_operador" className="form-control" type="text" placeholder="Id operador"value={theUser.id} autoComplete="off" readOnly />
                </div>

                <input name="id" className="form-control" type="text" placeholder="Id" value={frameworkSeleccionado && frameworkSeleccionado.id} autoComplete="off" readOnly />

                <div class="accordion-body">
                <input name="name_operador" className="form-control" type="text" placeholder="nombre operador" value={frameworkSeleccionado && frameworkSeleccionado.name_operador} autoComplete="off"  readOnly/>
                <input name="anulada" className="form-control" type="text" placeholder="anulada" value={frameworkSeleccionado && frameworkSeleccionado.anulada} autoComplete="off"  readOnly/>
                </div>

                <div className="form-group">
            <input name="documento" className="form-control" type="number" placeholder="N° identificación" value={theUser.documento} readOnly autoComplete="off" />
          </div>

          <div className="form-group">
            <input name="celular" className="form-control" type="number" placeholder="Celular" value={theUser.celular} readOnly autoComplete="off" />
          </div>

          <div className="form-group">
            <input name="nombre" className="form-control" type="text" placeholder="Nombres completos"  value={theUser.name} autoComplete="off" />
          </div>
           

              </div>
            </div>
            
            
          </div>
     
     

   </div>
<br/>
  {/* <button className="btn btn-success w-100" type="submit" onClick={loadData} disabled={loading}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-plus" viewBox="0 0 16 16">
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
  <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM8 8a.5.5 0 0 1 .5.5V10H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V11H6a.5.5 0 0 1 0-1h1.5V8.5A.5.5 0 0 1 8 8z"/>
</svg> Generar Novedad</button> */}


   </form>

  
   </div>
    
 </ModalBody>
 <ModalFooter>
  
<button className="btn btn-info w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Aceptar</button>

 </ModalFooter>
</Modal>

        
   </div>
  )
}

else if(showLogin){
    return"";
}

}

export default Sub_zona2_visor_dia