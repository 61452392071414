import React, {useState,useEffect,useContext} from 'react';
import axios from 'axios';

//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { AddBox, ArrowDownward } from "@material-ui/icons";

import {MyContext} from '../../login/MyContext';

//import SearchIcon from '@mui/icons-material/Search';
//import SearchIcon from '@material-ui/core/SearchIcon';



const Subzona_2 = () => {
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    const [data, setData]=useState([]);
    const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
        id:'',
        name:'',
        saldo:'',
      });
   
      const [valuevalor, setValuevalor] = useState("");

      const [modalInsertar, setModalInsertar]=useState(false);
      const [modalEditar, setModalEditar]=useState(false);

     
     
    
     
      //suma
      const [number, setNumber] = useState({valor: 0 })
      const [suma, setSuma] = useState()
  
    useEffect(()=>{
      const { valor } = number
      setSuma(Number(valor) * global.valorhora)
    },[number])
  
    const handleInput = (event) => {
      const { name, value } = event.target
      setNumber({...number, [name]: value})
    }



    const handleVehiculo = (event) => {
      setValuevalor(event.target.value)
      
      const { name, value } = event.target
      setNumber({valor:0})

    }

     
 
       ////////////////////////////////////////////////////////

    const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }



    const [valorhora, setTarifas] = useState([]);

  const peticionGet_tarifa =async() =>{
   // await axios.get(`https://www.parqueoenvia.com/apiParqueoenvia/tarifas/index.php/`)
   await axios.get(`https://www.parqueoenvia.com/apiParqueoenvia/tarifas/index.php?id=${global.one}`)
   
    .then((res) => {
       setTarifas(res.data.valorhora);
    }).catch(error=>{
      console.log(error);
    })
  
  }

  //console.log("revisar " + valorhora)

  const [name_v, setName] = useState([]);
  const [country_vahia, setCountry] = useState([]);
  const [v1, setV1] = useState([]);
  const [v2, setV2] = useState([]);
  const [v3, setV3] = useState([]);
  const [v4, setV4] = useState([]);
  const [v5, setV5] = useState([]);
  const [v6, setV6] = useState([]);
  const [v7, setV7] = useState([]);
  const [v8, setV8] = useState([]);
  const [v9, setV9] = useState([]);
  const [v10, setV10] = useState([]);
  const [v11, setV11] = useState([]);
  const [v12, setV12] = useState([]);
  const [v13, setV13] = useState([]);
  const [v14, setV14] = useState([]);
  const [v15, setV15] = useState([]);
  const [v16, setV16] = useState([]);
  const [v17, setV17] = useState([]);
  const [v18, setV18] = useState([]);
  const [v19, setV19] = useState([]);
  const [v20, setV20] = useState([]);

  const [v1_disponible, set_v1disponible] = useState([]);
  const [v2_disponible, set_v2disponible] = useState([]);
  const [v3_disponible, set_v3disponible] = useState([]);
  const [v4_disponible, set_v4disponible] = useState([]);
  const [v5_disponible, set_v5disponible] = useState([]);
  const [v6_disponible, set_v6disponible] = useState([]);
  const [v7_disponible, set_v7disponible] = useState([]);
  const [v8_disponible, set_v8disponible] = useState([]);
  const [v9_disponible, set_v9disponible] = useState([]);
  const [v10_disponible, set_v10disponible] = useState([]);
  const [v11_disponible, set_v11disponible] = useState([]);
  const [v12_disponible, set_v12disponible] = useState([]);
  const [v13_disponible, set_v13disponible] = useState([]);
  const [v14_disponible, set_v14disponible] = useState([]);
  const [v15_disponible, set_v15disponible] = useState([]);
  const [v16_disponible, set_v16disponible] = useState([]);
  const [v17_disponible, set_v17disponible] = useState([]);
  const [v18_disponible, set_v18disponible] = useState([]);
  const [v19_disponible, set_v19disponible] = useState([]);
  const [v20_disponible, set_v20disponible] = useState([]);



  const [confirmar_zona_v, set_Confirmar_zona_v] = useState([]);

  const peticionGet_vahias =async() =>{
        
    console.log("nombrevahia" + global.user_subzona)

   
  await axios.get(`https://parqueoenvia.com/apiParqueoenvia/zonas_app/vahias.php?country=${global.user_subzona}`)
  .then((res) => {
     setName(res.data.name);
     setCountry(res.data.country);
     set_Confirmar_zona_v(res.data.confirmar_zona);
     setV1(res.data.v1);
     setV2(res.data.v2);
     setV3(res.data.v3);
     setV4(res.data.v4);
     setV5(res.data.v5);
     setV6(res.data.v6);
     setV7(res.data.v7);
     setV8(res.data.v8);
     setV9(res.data.v9);
     setV10(res.data.v10);
     setV11(res.data.v11);
     setV12(res.data.v12);
     setV13(res.data.v13);
     setV14(res.data.v14);
     setV15(res.data.v15);
     setV16(res.data.v16);
     setV17(res.data.v17);
     setV18(res.data.v18);
     setV19(res.data.v19);
     setV20(res.data.v20);
     set_v1disponible(res.data.v1_disponible);
     set_v2disponible(res.data.v2_disponible);
     set_v3disponible(res.data.v3_disponible);
     set_v4disponible(res.data.v4_disponible);
     set_v5disponible(res.data.v5_disponible);
     set_v6disponible(res.data.v6_disponible);
     set_v7disponible(res.data.v7_disponible);
     set_v8disponible(res.data.v8_disponible);
     set_v9disponible(res.data.v9_disponible);
     set_v10disponible(res.data.v10_disponible);
     set_v11disponible(res.data.v11_disponible);
     set_v12disponible(res.data.v12_disponible);
     set_v13disponible(res.data.v13_disponible);
     set_v14disponible(res.data.v14_disponible);
     set_v15disponible(res.data.v15_disponible);
     set_v16disponible(res.data.v16_disponible);
     set_v17disponible(res.data.v17_disponible);
     set_v18disponible(res.data.v18_disponible);
     set_v19disponible(res.data.v19_disponible);
     set_v20disponible(res.data.v20_disponible);
    
   // console.log("nombrex" + res.data.celular);
  }).catch(error=>{
    console.log(error);
  })

}


const [id_unico_subzona, setId_unico_subzona] = useState([]);


const peticionGet_usuarios =async() =>{
        
    console.log("nombrevahia" + global.user_subzona )

   
  await axios.get(`https://parqueoenvia.com/apiParqueoenvia/zonas_app/sub_usuariosApp.php?id=${global.user_id}`)
  .then((res) => {
     setId_unico_subzona(res.data.id_unico_subzona);
  }).catch(error=>{
    console.log(error);
  })

}


{console.log(global.idunico = id_unico_subzona)}

/*
const [v1_vf, setV1_vf] = useState([]);
const [v2_vf, setV2_vf] = useState([]);
const [v3_vf, setV3_vf] = useState([]);
const [v4_vf, setV4_vf] = useState([]);
const [v5_vf, setV5_vf] = useState([]);
const [v6_vf, setV6_vf] = useState([]);
const [v7_vf, setV7_vf] = useState([]);
const [v8_vf, setV8_vf] = useState([]);
const [v9_vf, setV9_vf] = useState([]);
const [v10_vf, setV10_vf] = useState([]);
const [v11_vf, setV11_vf] = useState([]);
const [v12_vf, setV12_vf] = useState([]);
const [v13_vf, setV13_vf] = useState([]);
const [v14_vf, setV14_vf] = useState([]);
const [v15_vf, setV15_vf] = useState([]);
const [v16_vf, setV16_vf] = useState([]);
const [v17_vf, setV17_vf] = useState([]);
const [v18_vf, setV18_vf] = useState([]);
const [v19_vf, setV19_vf] = useState([]);
const [v20_vf, setV20_vf] = useState([]);


const peticionGet_n_vahias =async() =>{
        
    console.log("nombrevahia" + global.user_subzona)

   
  await axios.get(`https://parqueoenvia.com/apiParqueoenvia/zonas_app/n_vahias.php?id=2`)
  .then((res) => {
    setV1_vf(res.data.v1_disponible);
    setV2_vf(res.data.v2_disponible);
    setV3_vf(res.data.v3_disponible);
    setV4_vf(res.data.v4_disponible);
    setV5_vf(res.data.v5_disponible);
    setV6_vf(res.data.v6_disponible);
    setV7_vf(res.data.v7_disponible);
    setV8_vf(res.data.v8_disponible);
    setV9_vf(res.data.v9_disponible);
    setV10_vf(res.data.v10_disponible);
    setV11_vf(res.data.v11_disponible);
    setV12_vf(res.data.v12_disponible);
    setV13_vf(res.data.v13_disponible);
    setV14_vf(res.data.v14_disponible);
    setV15_vf(res.data.v15_disponible);
    setV16_vf(res.data.v16_disponible);
    setV17_vf(res.data.v17_disponible);
    setV18_vf(res.data.v18_disponible);
    setV19_vf(res.data.v19_disponible);
    setV20_vf(res.data.v20_disponible);
     
  }).catch(error=>{
    console.log(error);
  })

}

*/


const [saldo, setNames] = useState([]);

const peticionGet_saldo =async() =>{
    
    console.log("nombre" + global.miID)
   // let miID=global.miID
   

  await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/index.php?id=${global.miID}`)
  .then((res) => {
     setNames(res.data.saldo);
  }).catch(error=>{
    console.log(error);
  })

}


   

    const peticionGet =async() =>{
        // await axios.get(baseUrlAd)
         //await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/historial.php?idPuntoVenta=${global.id}`)
         await axios.get(`https://parqueoenvia.com/apiParqueoenvia/zonas_app/subzona_1.php?country=2`)
         .then(response=>{
          setData(response.data);
         }).catch(error=>{
           console.log(error);
         })
     
       }



    /*const peticionGet =async() =>{
        await axios.get(`https://parqueoenvia.com/apiParqueoenvia/saldos/index.php?id=${global.miID}`)
        .then(response=>{
         setData(response.data);
        }).catch(error=>{
          console.log(error);
        })
    
      }*/


     
      const handleChange=e=>{
        const {name, value}=e.target;
        setFrameworkSeleccionado((prevState)=>({
          ...prevState,
          [name]: value
        }))
       console.log(frameworkSeleccionado);
      }
    
      const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
      }
   

useEffect(()=>{
    

        const interval = setInterval(() => {
          
           peticionGet();
           peticionGet_saldo();
           peticionGet_tarifa();
           peticionGet_vahias(); 
           peticionGet_usuarios();
         //  peticionGet_n_vahias(); 
           
         }, 1000);
     
       return () => setInterval(interval);
     
       },[])


       const seleccionarFramework=(framework, caso)=>{
        setFrameworkSeleccionado(framework);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEditar()
    
    
      }


       const columnas =[      
        {
          title:"ZONA",
          field: "confirmar_zona"
        },

        {
            title:"SUB-ZONA",
            field: "name"
          },
    
        
       
    
    
        
        
      ];
    
  
       if(isAuth)
       {
  return (
       <div class="container direction-rtl" style={{marginTop:"-45px"}}>
       
   {/* <div class="card timeline-card bg-success" >
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div class="timeline-text mb-2"><span class="badge mb-2 rounded-pill">HISTORIAL DE RECARGAS</span>
                <h5>${saldo} COP</h5>
              </div>
              <div class="timeline-icon mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
  <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
  <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
  <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
</svg>
              </div>
            </div>
                  </div>
        </div>
  */}


        <MaterialTable

     
columns={columnas}
data={data}
title= { <><i class="fas fa-hands-helping fa-2x"></i>Tabla&nbsp;</> }
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'paid',
        tooltip: 'Ver Resumen',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
  
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
/*localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}*/



/>
<br/>
<br/>
<br/>

<Modal isOpen={modalEditar}>
 {/*<ModalHeader  style={{backgroundColor:"#F4F4F4",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">MI RECARGA</font></ModalHeader>*/}

 <ModalBody className="card timeline-card bg-success" style={{padding:"0rem"}}>

 


          <div class="card-body">
 

          <form className="fs-13px" method="post" action="https://parqueoenvia.com/email/recarga_cliente.php" target="_parent" name="reset" noValidate>
           
           <div class="row">
{console.log(global.valorhora = valorhora)}

<center><h6 className='btn btn-warning'>Mi saldo: ${saldo} COP</h6></center>
<p>----------- Bahías disponibles  -----------</p>

<div class="accordion accordion-style-three" id="accordionStyle3">
             
              <div class="accordion-item">
                <div class="accordion-header" id="accordionSeven">
                  <h6 class="shadow-sm rounded border" data-bs-toggle="collapse" data-bs-target="#accordionStyleSeven" aria-expanded="true" aria-controls="accordionStyleSeven">Ocupe una Bahía
                    <svg class="bi bi-arrow-down-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"></path>
                    </svg>
                  </h6>
                </div>
                <div class="accordion-collapse collapse " id="accordionStyleSeven" aria-labelledby="accordionSeven" data-bs-parent="#accordionStyle3" >
                  <div class="accordion-body">

                    
               
                
                  { frameworkSeleccionado.v1_disponible =="si"  && frameworkSeleccionado.v1 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v1" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v1} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v1_disponible" name="v1_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
           </>

            : ""
        } 

            { frameworkSeleccionado.v2_disponible =="si"  && frameworkSeleccionado.v2 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v2" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v2} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v2_disponible" name="v2_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


{ frameworkSeleccionado.v3_disponible =="si"  && frameworkSeleccionado.v3 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v3" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v3} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v3_disponible" name="v3_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


{ frameworkSeleccionado.v4_disponible =="si"  && frameworkSeleccionado.v4 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v4" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v4} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v4_disponible" name="v4_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 
 

{ frameworkSeleccionado.v5_disponible =="si"  && frameworkSeleccionado.v5 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v5" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v5} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v5_disponible" name="v5_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


{ frameworkSeleccionado.v6_disponible =="si"  && frameworkSeleccionado.v6 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v6" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v6} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v6_disponible" name="v6_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


{ frameworkSeleccionado.v7_disponible =="si"  && frameworkSeleccionado.v7 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v7" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v7} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v7_disponible" name="v7_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 



{ frameworkSeleccionado.v8_disponible =="si"  && frameworkSeleccionado.v8 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v8" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v8} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v8_disponible" name="v8_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


        { frameworkSeleccionado.v9_disponible =="si"  && frameworkSeleccionado.v9 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v9" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v9} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v9_disponible" name="v9_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


{ frameworkSeleccionado.v10_disponible =="si"  && frameworkSeleccionado.v10 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v10" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v10} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v10_disponible" name="v10_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


{ frameworkSeleccionado.v11_disponible =="si"  && frameworkSeleccionado.v11 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v11" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v11} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v11_disponible" name="v11_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


{ frameworkSeleccionado.v12_disponible =="si"  && frameworkSeleccionado.v12 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v12" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v12} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v12_disponible" name="v12_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


{ frameworkSeleccionado.v13_disponible =="si"  && frameworkSeleccionado.v13 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v13" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v13} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v13_disponible" name="v13_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


{ frameworkSeleccionado.v14_disponible =="si" && frameworkSeleccionado.v14 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v14" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v14} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v14_disponible" name="v14_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


{ frameworkSeleccionado.v15_disponible =="si"  && frameworkSeleccionado.v15 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v15" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v15} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v15_disponible" name="v15_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 

{ frameworkSeleccionado.v16_disponible =="si"  && frameworkSeleccionado.v16 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v16" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v16} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v16_disponible" name="v16_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


{ frameworkSeleccionado.v17_disponible =="si"  && frameworkSeleccionado.v17 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v17" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v17} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v17_disponible" name="v17_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


{ frameworkSeleccionado.v18_disponible =="si"  && frameworkSeleccionado.v18 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v18" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v18} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v18_disponible" name="v18_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 



{ frameworkSeleccionado.v19_disponible =="si" && frameworkSeleccionado.v19 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v19" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v19} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v19_disponible" name="v19_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 

{ frameworkSeleccionado.v20_disponible =="si" && frameworkSeleccionado.v20 !=""
     ?
     <>
           <p style={{color:"blue"}}> ---------------- Bahía -----------------  </p>
                  <div className="form-group">
            <input name="v20" className="form-control" type="text" placeholder="Bahía" value={frameworkSeleccionado.v20} autoComplete="off" readOnly />
          </div>
                  <div class="form-group">
              <label class="form-label" for="defaultSelect">Usar</label>
              <select class="form-select form-control-clicked" id="v20_disponible" name="v20_disponible" aria-label="Default select example">
                <option value="si" selected="">Seleccionar</option>
                <option value="no">SI</option>
              </select>
            </div>

            <p style={{color:"blue"}}> ----------------------------------------  </p>
            </>
            : ""
        } 


       
          
                  </div>
                </div>
              </div>
              
             
              

             
            </div>

            
            <br/>
<p>----------------- Tarifa  -----------------</p>

<div class="form-group col-6">
              <label class="form-label" for="defaultSelect">Tipo vehículo</label>
              <select class="form-select form-control-clicked" id="mivehiculo" name="mivehiculo" aria-label="Default select example"
              value={valuevalor} onChange={handleVehiculo}  >
                 <option value="0" selected>Seleccionar</option>
                <option value="1" >Carro</option>
                <option value="2">Moto</option>
              </select>
            </div>

           <div class="form-group col-6">
            
              <label class="form-label" for="defaultSelect" >Tiempo en Bahía</label>
              <select class="form-select form-control-clicked" id="valor" name="valor" aria-label="Default select example"
               onChange={handleInput} value={number.valor}>
                <option value="0" selected="">Seleccione</option>
                <option value="1">1 hora</option>
                <option value="2">2 horas</option>
                <option value="3">3 horas</option>
                <option value="4">4 horas</option>
                <option value="5">5 horas</option>
              </select>
            </div>

          <div style={{display:"none"}}>
          <input name="valor_t" className="form-control" type="number" placeholder="" autoComplete="off" value={global.one = valuevalor} readOnly />
                            
                            { global.one =="1"
                      ?<>
                                <input  name="t_vehiculo" className="form-control" type="text" value="Carro" autoComplete="off" />
                      </>

                      : ""
                      } 


                  { global.one =="2"
                      ?<>
                                <input  name="t_vehiculo" className="form-control" type="text" value="Moto" autoComplete="off" />
                      </>

                      : ""
                      } 

          
          </div>
            
          

            <div className="form-group col-12">
          <label class="form-label" for="defaultSelect">Valor a cobrar</label>
            <input name="valor_t" className="form-control" type="number" placeholder="" autoComplete="off" value={suma} readOnly />
          </div>
         
     
          </div>

        {/*  <div class="form-group">
              <label class="form-label" for="defaultSelect">Tipo vehículo</label>
              <select class="form-select form-control-clicked" id="t_vehiculo" name="t_vehiculo" aria-label="Default select example">
                <option value="Carro" selected="">Carro</option>
                <option value="Moto">Moto</option>
              </select>
            </div> */}

          <div className="form-group">
            <input name="placa" className="form-control" type="text" placeholder="Placa vehículo" autoComplete="off" />
          </div>
<div style={{display:"none"}}>
          <p>------------- Datos Usuario  -------------</p>

          <div className="form-group">
            <input name="email" className="form-control" type="email" placeholder="Email" autoComplete="off" value={theUser.email} readOnly />
          </div>

          <div class="form-group">
              <label class="form-label" for="defaultSelect">Tipo documento</label>
              <select class="form-select form-control-clicked" id="t_documento" name="t_documento" aria-label="Default select example">
                <option value="C.C" selected="">C.C</option>
                <option value="Pasaporte">Pasaporte</option>
              </select>
            </div>

          <div className="form-group">
            <input name="documento" className="form-control" type="number" placeholder="N° identificación" value={theUser.documento} readOnly autoComplete="off" />
          </div>

          <div className="form-group">
            <input name="celular" className="form-control" type="number" placeholder="Celular" value={theUser.celular} readOnly autoComplete="off" />
          </div>

          <div className="form-group">
            <input name="nombre" className="form-control" type="text" placeholder="Nombres completos"  value={theUser.name} autoComplete="off" />
          </div>

          </div>   

          { saldo >= suma
          ?  
         <>
         <button className="btn btn-success w-100" type="submit" onClick={loadData} disabled={loading}> GENERAR RECARGA </button><br/>
         </>
         : ""
        }    


       { saldo < suma
          ?  
         <>
         <center><h6 className='btn btn-danger' >Saldo insuficiente, te falta ${suma - saldo} COP</h6></center><br/>
         
         </>
         : ""
        }    
        
         <br/>
         <div class="accordion accordion-flush accordion-style-one" id="accordionStyle1" style={{display:"none"}}>
          
  
            <div class="accordion-item">
              <div class="accordion-header" id="accordionTwo">
                <h6 class="collapsed" data-bs-toggle="collapse" data-bs-target="#accordionStyleTwo" aria-expanded="false" aria-controls="accordionStyleTwo">Operador<i class="bi bi-chevron-down"></i></h6>
              </div>
              <div class="accordion-collapse collapse" id="accordionStyleTwo" aria-labelledby="accordionTwo" data-bs-parent="#accordionStyle1" >
                <div class="accordion-body">
                <input name="confirmar_zona" className="form-control" type="text" placeholder="confirmar_zona" value={frameworkSeleccionado.confirmar_zona} autoComplete="off" readOnly />
                <input name="id_users" className="form-control" type="text" placeholder="id-usuario" autoComplete="off" value={theUser.id} readOnly />
                </div>

                <div class="accordion-body">
                <input name="name" className="form-control" type="text" placeholder="sub_zona" value={frameworkSeleccionado.name} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="country" className="form-control" type="text" placeholder="country" value={frameworkSeleccionado.country} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="id_subzona" className="form-control" type="text" placeholder="id_subzona" value={frameworkSeleccionado.id_subzona} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="confirmar_zona_v" className="form-control" type="text" placeholder="Sub-zona" value={confirmar_zona_v} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="name_v" className="form-control" type="text" placeholder="Zona" value={name_v} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="id_unico_subzona" className="form-control" type="text" placeholder="id unico" value={id_unico_subzona} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v1_vf" className="form-control" type="text" placeholder="v1_vf" value={frameworkSeleccionado.v1_disponible} autoComplete="off"  readOnly/>
                </div>


                <div class="accordion-body">
                <input name="v2_vf" className="form-control" type="text" placeholder="v2_vf" value={frameworkSeleccionado.v2_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v3_vf" className="form-control" type="text" placeholder="v3_vf" value={frameworkSeleccionado.v3_disponible} autoComplete="off"  readOnly/>
                </div>


                <div class="accordion-body">
                <input name="v4_vf" className="form-control" type="text" placeholder="v4_vf" value={frameworkSeleccionado.v4_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v5_vf" className="form-control" type="text" placeholder="v5_vf" value={frameworkSeleccionado.v5_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v6_vf" className="form-control" type="text" placeholder="v6_vf" value={frameworkSeleccionado.v6_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v7_vf" className="form-control" type="text" placeholder="v7_vf" value={frameworkSeleccionado.v7_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v8_vf" className="form-control" type="text" placeholder="v8_vf" value={frameworkSeleccionado.v8_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v9_vf" className="form-control" type="text" placeholder="v9_vf" value={frameworkSeleccionado.v9_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v10_vf" className="form-control" type="text" placeholder="v10_vf" value={frameworkSeleccionado.v10_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v11_vf" className="form-control" type="text" placeholder="v11_vf" value={frameworkSeleccionado.v11_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v12_vf" className="form-control" type="text" placeholder="v12_vf" value={frameworkSeleccionado.v12_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v13_vf" className="form-control" type="text" placeholder="v13_vf" value={frameworkSeleccionado.v13_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v14_vf" className="form-control" type="text" placeholder="v14_vf" value={frameworkSeleccionado.v14_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v15_vf" className="form-control" type="text" placeholder="v15_vf" value={frameworkSeleccionado.v15_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v16_vf" className="form-control" type="text" placeholder="v16_vf" value={frameworkSeleccionado.v16_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v17_vf" className="form-control" type="text" placeholder="v17_vf" value={frameworkSeleccionado.v17_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v18_vf" className="form-control" type="text" placeholder="v18_vf" value={frameworkSeleccionado.v18_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v19_vf" className="form-control" type="text" placeholder="v19_vf" value={frameworkSeleccionado.v19_disponible} autoComplete="off"  readOnly/>
                </div>

                <div class="accordion-body">
                <input name="v20_vf" className="form-control" type="text" placeholder="v20_vf" value={frameworkSeleccionado.v20_disponible} autoComplete="off"  readOnly/>
                </div>
                 







           

              </div>
            </div>
            
            
          </div>
   
                
       
        </form>
   
     
        <button className="btn btn-danger w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> CANCELAR</button>
 
     
</div>
  
 </ModalBody>
 
</Modal>

        
   </div>
  )
}

else if(showLogin){
    return"";
}

}

export default Subzona_2