import React, {useState,useContext} from 'react';

import {Link} from 'react-router-dom';

//import '../../App.css';
import {MyContext} from '../login/MyContext';

/*import LogoMenu from '../assets/plantilla/menu/servidor.gif';
import LogoUsuario from '../assets/plantilla/menu/user2-160x160.jpg';
import Usuarios from '../assets/plantilla/menu/usuarios.png';
import Crear_usuarios from '../assets/plantilla/menu/crear_usuarios.png';
import Ver_usuarios from '../assets/plantilla/menu/ver_usuarios.png';
import Compras from '../assets/plantilla/menu/compras.png';
import Ingresos from '../assets/plantilla/menu/ingresos.png';
import Proveedores from '../assets/plantilla/menu/proveedores.png';
import Ventas from '../assets/plantilla/menu/ventas.png';
import Ventas_submenu from '../assets/plantilla/menu/ventas_submenu.png';
import Clientes from '../assets/plantilla/menu/clientes.png';
import Inventarios from '../assets/plantilla/menu/inventarios.png';
import Categorias from '../assets/plantilla/menu/categorias.png';
import Articulos from '../assets/plantilla/menu/articulos.png';
import Reportes from '../assets/plantilla/menu/reportes.png';
import Reportes_submenu from '../assets/plantilla/menu/reportes_submenu.png';
import Kardex from '../assets/plantilla/menu/kardex.png';*/

function Menu(props) {
  const {rootState,logoutUser} = useContext(MyContext);
  
  const [dropdown, setDropdown]=useState(false);
  const {isAuth,theUser,showLogin} = rootState;

  const abrirCerrarDropdown=()=>{
    setDropdown(!dropdown);
  }
    
  return (
<>
   


</>

  /* <nav classNameName="navbar  main-sidebar sidebar-light-primary elevation-4 " >
  
  
   <center>
  <a href="#" classNameName="brand-link">
      <img src={LogoMenu} alt="ODIN" width="50px" />
      <span classNameName="brand-text font-weight-light"></span>
    </a>   </center>  

   
    <div classNameName=" scrollb ">
      <div classNameName="user-panel mt-3 pb-3 mb-3 d-flex">
        
        <div classNameName="info">
          <br/>
          <h6  classNameName="d-block"><b>{theUser.name}</b></h6>

          
          <button classNameName="btn btn-danger " onClick={logoutUser} > Salir <i classNameName="fas fa-sign-out-alt"></i></button>
            
            
            
        </div>
      </div>

      <nav classNameName="mt-2">
    
   
      <ul classNameName="nav navbar-nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">         
      

{ theUser.rol =="admin"
          ?<><li classNameName="nav-item" >
          <Link to="/Shoping" classNameName="nav-link">
          
          <i className="fa fa-shopping-cart fa-2x" style={{color:"#14CF01"}}></i>
            <p><font size="3">
               &nbsp; Shoping
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li>

        <li classNameName="nav-item" >
          <Link to="/Productos" classNameName="nav-link">
          
          <i className="fa fa-server fa-2x" style={{color:"#17A6FB"}}></i>
            <p><font size="3">
               &nbsp; Mis productos
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li>
          
          <li classNameName="nav-item" >
          <Link to="/Odin" classNameName="nav-link">
          
          <i className="fas fa-cog fa-spin fa-2x" style={{color:"#17A6FB"}}></i>
            <p><font size="3">
               &nbsp; ODÍN IA
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li>

        <li classNameName="nav-item" >
          <Link to="/Esquemas" classNameName="nav-link">
          
          <i className="fa fa-database fa-2x" aria-hidden="true" style={{color:"#F9900B"}}></i>
            <p><font size="4">
               &nbsp; Esquemas
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li>

        <li classNameName="nav-item" >
          <Link to="/Proyectos" classNameName="nav-link">
          
          <i className="fa fa-file-code-o fa-2x" aria-hidden="true" style={{color:"#F9900B"}}></i>
            <p><font size="4">
               &nbsp; Proyectos
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li>

        <li classNameName="nav-item" >
          <Link to="/Clones" classNameName="nav-link">
          
          <i className="fa fa-cubes fa-2x" aria-hidden="true" style={{color:"#F9900B"}}></i>
            <p><font size="4">
               &nbsp; Clones
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li>


        <li classNameName="nav-item" >
          <Link to="/Manuales" classNameName="nav-link">
          
          <i className="fa fa-file-pdf-o fa-2x" aria-hidden="true" style={{color:"#F9900B"}}></i>
            <p><font size="4">
               &nbsp; Manuales
               </font>
             <b classNameName="caret" />
           </p>
            </Link>
        </li> 

   

    </>

: ""
}







      </ul>
      

     

      <ul classNameName="nav navbar-nav nav-pills nav-sidebar flex-column">
      
        
      </ul>
    
    </nav>
  </div>

</nav> */
       
    )

    
  }

 


export default Menu