import React,{useState} from 'react'
import ReactAudioPlayer from 'react-audio-player';



const SoundCliente = () => {



  const [soundCliente, setSound] = useState(true);



  return (
    <>


<ReactAudioPlayer
  src="img\demo-img\poli.mp3"
 autoPlay={soundCliente}
 
 // onPlay={setSound}
  //controls
  allow
  loop="true"
/>

</>
  )
}

export default SoundCliente